import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import React, { useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import { onInputChange } from '../../../services/utils/input-services';
import { PageHeader } from '../../../views/page-header';
import '../styles/reset-password-style.scss';
import { NewPasswordRequest, blankNewPasswordRequest } from '../types/types';
import blackLogo from './../../../wwroot/img/moon-black-logo.png';
import whiteLogo from './../../../wwroot/img/moon-white-logo.png';
import { UserServices } from '../../../services/user-services';
import { ToastServices } from '../../../services/utils/toast-services';
import { useLocation } from "react-router-dom";

export const PasswordReset = () => {
    const search = useLocation().search;
    const _userServices = new UserServices();
    const _toastServices = new ToastServices();


    const [invalidToken, setInvalidToken] = useState<boolean>(false);

    const [token, setToken] = useState<string>('');
    const [passwordRequest, setPasswordRequest] = useState<NewPasswordRequest>(blankNewPasswordRequest);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const _token = new URLSearchParams(search).get("token");

        if (_token === null || _token === undefined) {
            _toastServices.showErrorMessage("Token inválido!");
            setInvalidToken(true);
        }

        setToken(_token);
        setLoading(false)
    }, []);

    const getImageSource = () => {
        var userTheme = localStorage.getItem("user-theme") || 'claro';

        return userTheme === 'claro' ? blackLogo : whiteLogo;
    }
    const onUpdatePassword = () => {
        if (validatePasswords) {
            setLoading(true);
            _userServices.updatePassword(token, passwordRequest.newPassword)
                .then(data => {
                    _toastServices.showSuccessMessage("Senha atualizada com sucesso!");
                }).catch(error => { _toastServices.showErrorRequestMessage(error); setLoading(false); });
        } else {
            _toastServices.showErrorMessage("Senhas não são iguais!");
        }
    }
    const validatePasswords = () => {
        if (passwordRequest.newPassword === passwordRequest.confirmNewPassword) {
            return true;
        }

        return false;
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className='flex justify-content-center'>
                    <img className='reset-password-logo' src={getImageSource()} alt='olho-vivo-logo' />
                </div>
                <PageHeader title="REDEFINIR SENHA" icon="pi pi-lock" />
                <div className="card">

                    <div className='grid mt-4'>
                        <div className='col-3'>
                            <strong>Nova Senha:</strong>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                <Password
                                    id="ddModelo"
                                    promptLabel='Digite uma nova senha'
                                    weakLabel='Senha muito fraca!'
                                    min={6}
                                    mediumLabel='Senha mediana!'
                                    strongLabel='Boa!'
                                    title='Digite uma senha'
                                    value={passwordRequest.newPassword}
                                    required
                                    disabled={invalidToken}
                                    onChange={(e) => onInputChange(e, "newPassword", passwordRequest, setPasswordRequest)}
                                    placeholder="digite uma nova senha"
                                />
                            </div>
                        </div>
                        <div className='col-3'>
                            <strong>Confirmar nova senha:</strong>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                <Password
                                    id="ddModelo"
                                    value={passwordRequest.confirmNewPassword}
                                    required
                                    min={6}
                                    disabled={invalidToken}
                                    feedback={false}
                                    onChange={(e) => onInputChange(e, "confirmNewPassword", passwordRequest, setPasswordRequest)}
                                    placeholder="digite uma nova senha"
                                />
                            </div>
                        </div>
                        <div className='col-12 flex'>
                            {!validatePasswords() && <small className="p-invalid">Senhas não são iguais!</small>}
                        </div>
                        <div className='col-12 flex'>
                            <Button label='ATUALIZAR SENHA' disabled={invalidToken || !validatePasswords()} onClick={() => onUpdatePassword()} />
                            <div className='clip-loader-container ml-2'>
                                <ClipLoader color='var(--text-color)' loading={loading} size={25} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
