import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import React from 'react';
import { environment } from '../../../configuration/environment';
import { ToastServices } from '../../../services/utils/toast-services';
import { CameraServices } from '../../../services/camera-services';
import { CameraIntelligenceResponse } from '../../Camera/types/types';

export interface LocationBikerBehaviorCameraGridProps {
    loading: boolean,
    selectedLocation: number,
    setLoading: (val: boolean) => void
    cameras: CameraIntelligenceResponse[],
    getLocationCameras: (selectedLocation: number) => void,
}

export const LocationBikerBehaviorCameraGrid = (props: LocationBikerBehaviorCameraGridProps) => {
    const _cameraServices = new CameraServices();
    const _toastServices = new ToastServices();

    const getCameraScreenShotURL = (cameraId: number): string => {
        return environment().baseUrl + environment().version + "/dashboard/snapshot/" + cameraId;
    }
    const getToggleButton = (cameraConvoy: CameraIntelligenceResponse) => {
        let convoyEnabled: boolean = cameraConvoy.indBikerBehavior;
        let label: string = 'DESLIGADO';
        let icon: string = 'pi pi-times';
        let severity: any = 'warning';

        if (convoyEnabled === true) {
            label = 'LIGADO';
            icon = 'pi pi-check';
            severity = 'success';
        }

        return <Button severity={severity} label={label} icon={icon} onClick={(e) => onChangedBikerBehavior(cameraConvoy)} className="w-9rem" />
    }

    const renderGridItem = (cameraConvoy: CameraIntelligenceResponse) => {
        return (
            <>
                <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
                    <div className="p-4 border-1 surface-border surface-card border-round">
                        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                            <div className="flex align-items-center gap-2">
                                <i className="pi pi-camera"></i>
                                <span className="product-category">{cameraConvoy.camera}</span>
                            </div>
                        </div>
                        <div className="flex flex-column align-items-center gap-3 py-5">
                            <img src={getCameraScreenShotURL(cameraConvoy.cameraId)} alt={cameraConvoy.camera} className='convoy-image' />
                        </div>
                        <div className='flex justify-content-between'>
                            <div className="grid">
                                <div className='col-12'>
                                    <span className="text-2xl font-semibold">Análise Comportamental de Motoqueiros</span>
                                </div>
                                <div className='col-12'>
                                    {getToggleButton(cameraConvoy)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
    const itemTemplate = (cameraConvoy: CameraIntelligenceResponse) => {
        if (!cameraConvoy) {
            return;
        }

        return renderGridItem(cameraConvoy);
    }
    const dataGrid = () => {
        return (
            <DataView
                header='Câmeras da Localidade'
                value={props.cameras}
                itemTemplate={itemTemplate}
                layout='grid'
                emptyMessage="Nenhuma câmera encontrada para o filtro selecionado"
                rows={3}
                paginator>
            </DataView>
        );
    }

    const onChangedBikerBehavior = (cameraBikerBehavior: CameraIntelligenceResponse) => {
        props.setLoading(true);
        _cameraServices.setCameraIntelligenceConfiguration({
            cameraId: cameraBikerBehavior.cameraId,
            bikerBehaviorMode: !cameraBikerBehavior.indBikerBehavior,
        }).then(data => {
            _toastServices.showSuccessMessage("Camera " + cameraBikerBehavior.camera + " atualizada");
            props.setLoading(false);
            props.getLocationCameras(props.selectedLocation);
        }).catch((error) => { _toastServices.showErrorRequestMessage(error); props.setLoading(false); });
    }

    return (
        <React.Fragment>
            {props.selectedLocation !== 0 && !props.loading && dataGrid()}
        </React.Fragment>
    )
}
