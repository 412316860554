import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { ClipLoader } from 'react-spinners';
import { DropdownInfo } from '../../../../models/types';

export interface LocationMachineryVehicleHeaderProps {
    selectedLocation: number,
    locations: DropdownInfo[],
    loading: boolean,
    onSelectedUF: (e: any) => void,
    globalFilter: string,
    setGlobalFilter: (val: string) => void
}

export const LocationMachineryVehicleHeader = (props: LocationMachineryVehicleHeaderProps) => {

    return (
        <div className='grid'>
            <div className="col-4 lg:col-6 xl:col-2">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-map-marker" />
                    </span>
                    <Dropdown
                        options={props.locations}
                        value={props.selectedLocation}
                        optionLabel="description"
                        optionValue="id"
                        disabled={props.loading}
                        placeholder="Selecione uma Localidade"
                        onChange={(e) => props.onSelectedUF(e)}
                        emptyMessage="Nenhuma Camera Encontrada.">
                    </Dropdown>
                </div>
            </div>
            {props.selectedLocation !== 0 &&
                <div className="col-4 lg:col-6 xl:col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-camera" />
                        </span>
                        <InputText
                            value={props.globalFilter}
                            disabled={props.loading || props.selectedLocation === 0}
                            placeholder="Câmera"
                            onInput={(e: any) => props.setGlobalFilter(e.target.value)}
                        />
                    </div>
                </div>
            }
            <div className="col-4 lg:col-0 xl:col-8">
                <ClipLoader color='var(--text-color)' loading={props.loading} size={25} />
            </div>
        </div>
    );
}
