import { toast, ToastContent } from "react-toastify";

export class ToastServices {
    showSuccessMessage(message: string): void {
        toast.success(message);
    }

    showErrorMessage(message: string): void {
        toast.error(message, {
            autoClose: 2000
        });
    }

    showWarningMessage(message: string): void {
        toast.warning(message, {
            autoClose: 1000
        });
    }

    showErrorRequestMessage(error: any): void {
        try {
            error.response.data.forEach((message: string) => {
                setTimeout(() => {
                    toast.error(message);
                }, 20);
            });
        } catch {
            toast.error(`Houve algum problema com a requisição`);
        }
    }

    showSuccessRequestMessage(error: any): void {
        try {
            error.data.forEach((message: string) => {
                setTimeout(() => {
                    toast.success(message);
                }, 20);
            });
        } catch {
            toast.error(`Houve algum problema com a requisição`);
        }
    }

    showDashboardInfoMessage(title: string, message: string) {

        var content: ToastContent = {
            key: title,
            children: message
        };

        toast.info(content, {
            autoClose: 1000,
            position: 'bottom-left'
        })
    }
}
