import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import { environment } from "../../../configuration/environment";
import { DropdownInfo } from "../../../models/types";
import { AuthServices } from "../../../services/utils/auth-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import { CameraServices } from "../../../services/camera-services";
import { CameraIntelligenceResponse } from "../../Camera/types/types";
import { FeatureServices } from "../../../services/feature-services";
import "./../styles/no-plate-camera.scss";
import { LocationNoPlateHeader } from "./components/location-no-plate-header";

export const CameraNoPlateConfiguration = () => {
    const _authServices = new AuthServices();
    const _toastServices = new ToastServices();
    const _cameraServices = new CameraServices();
    const _featureServices = new FeatureServices();

    const [loading, setLoading] = useState<boolean>(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [loadingCameras, setLoadingCameras] = useState<boolean>(true);
    const [cameraList, setCameraList] = useState<CameraIntelligenceResponse[]>([]);

    _authServices.HandleAppAuth();

    useEffect(() => {
        setLoading(true);
        _featureServices
            .getLocationsByFeature("VEICULO-SEM-PLACA")
            .then((data: any) => {
                setLocations(data.data);
                setLoading(false);
            })
            .catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (selectedLocation !== 0) {
            getCameraNoPlateConfiguration(selectedLocation);
        }
    }, [selectedLocation]);

    const getFilteredCameraList = () => {
        if (globalFilter) {
            return cameraList.filter((camera) => camera.camera.toLowerCase().includes(globalFilter.toLowerCase()));
        }

        return cameraList;
    };

    const onSelectedUF = (e: { value: any }) => {
        setLoading(true);
        setSelectedLocation(e.value);
        setLoadingCameras(true);
    };

    const getCameraNoPlateConfiguration = (selectedLocation: number) => {
        setLoadingCameras(true);

        _cameraServices
            .getCamerasIntelligenceConfiguration(selectedLocation)
            .then((data) => {
                setCameraList(data.data);
                setLoadingCameras(false);
                setLoading(false);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoadingCameras(false);
            });
    };

    const onChangedNoPlate = (cameraNoPlate: CameraIntelligenceResponse) => {
        setLoadingCameras(true);
        _cameraServices
            .setCameraIntelligenceConfiguration({
                cameraId: cameraNoPlate.cameraId,
                noPlateMode: !cameraNoPlate.indNoPlate,
            })
            .then((data) => {
                _toastServices.showSuccessMessage("Camera " + cameraNoPlate.camera + " atualizada");
                // getCameraNoPlateConfiguration(selectedLocation);
                setCameraList((prevCameraList) =>
                    prevCameraList.map((camera) =>
                        camera.cameraId === cameraNoPlate.cameraId
                            ? { ...camera, indNoPlate: !camera.indNoPlate }
                            : camera
                    )
                );
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
            });
    };

    const getImageSrc = (cameraId: number): string => {
        return environment().baseUrl + environment().version +  "/dashboard/snapshot/" + cameraId;
    };

    const getToggleButton = (cameraNoPlate: CameraIntelligenceResponse) => {
        let noPlateEnabled: boolean = cameraNoPlate.indNoPlate;
        let label: string = "DESLIGADO";
        let icon: string = "pi pi-times";
        let severity: any = "warning";

        if (noPlateEnabled === true) {
            label = "LIGADO";
            icon = "pi pi-check";
            severity = "success";
        }

        return <Button severity={severity} label={label} icon={icon} onClick={(e) => onChangedNoPlate(cameraNoPlate)} className="w-9rem" />;
    };

    const renderGridItem = (cameraNoPlate: CameraIntelligenceResponse) => {
        return (
            <>
                <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
                    <div className="p-4 border-1 surface-border surface-card border-round">
                        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                            <div className="flex align-items-center gap-2">
                                <i className="pi pi-camera"></i>
                                <span className="product-category">{cameraNoPlate.camera}</span>
                            </div>
                        </div>
                        <div className="flex flex-column align-items-center gap-3 py-5">
                            <img src={getImageSrc(cameraNoPlate.cameraId)} alt={cameraNoPlate.camera} className="no-plate-image" />
                        </div>
                        <div className="flex justify-content-between">
                            <div className="grid">
                                <div className="col-12">
                                    <span className="text-2xl font-semibold">Veículo Sem Placa</span>
                                </div>
                                <div className="col-12">{getToggleButton(cameraNoPlate)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const itemTemplate = (cameraNoPlate: CameraIntelligenceResponse) => {
        if (!cameraNoPlate) {
            return;
        }

        return renderGridItem(cameraNoPlate);
    };

    const renderSkeletonDataScroller = () => {
        return (
            <>
                <div className="card">
                    <div className="product-item">
                        <Skeleton shape="rectangle" width="250px" height="145px" />
                        <div className="product-detail ml-2">
                            <div className="flex">
                                <i className="pi pi-tag product-category-icon"></i>
                                <span className="product-category">
                                    <Skeleton shape="rectangle" width="150px" height="25px" />
                                </span>
                            </div>
                        </div>
                        <div className="product-action">
                            <div className="flex flex-column align-items-center gap-2">
                                <strong className="" style={{ fontSize: "18px" }}>
                                    Veículo Sem Placa
                                </strong>
                            </div>
                            <div className="flex flex-column align-items-center gap-2">
                                <Skeleton shape="rectangle" width="120px" height="30px" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const renderDataScroller = () => {
        return <DataView header="Câmeras da Localidade" value={getFilteredCameraList()} itemTemplate={itemTemplate} layout="grid" emptyMessage="Nenhuma imagem encontrada para o filtro selecionado" rows={12} paginator></DataView>;
    };

    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader title="CONFIGURAÇÃO VEÍCULO SEM PLACA" icon="pi pi-chart-bar" />
                <div className="card">
                    <LocationNoPlateHeader globalFilter={globalFilter} loading={loading} locations={locations} onSelectedUF={onSelectedUF} selectedLocation={selectedLocation} setGlobalFilter={setGlobalFilter} />
                    {selectedLocation !== 0 && !loadingCameras && (
                        <div className="grid">
                            <div className="col-12"></div>
                            <div className="col-12">{loadingCameras ? renderSkeletonDataScroller() : renderDataScroller()}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
