import { faBus, faCar, faMotorcycle, faQuestion, faTruck, faTruckPickup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { cardResponse } from '../../../../models/types';
import '../../styles/vehicle-type.scss';
import { Fieldset } from 'primereact/fieldset';

export interface cardProps {
    cardResponse: cardResponse;
    isOutDated: boolean,
    isLoading: boolean
}

export const VehicleType = (props: cardProps) => {
    const textClasses = 'flex text-500 font-medium mb-1';
    const renderSkeletonVehicleTypeValue = <Skeleton shape="rectangle" width='100px' height='30px' />;
    const iconsClasses = 'flex align-items-center justify-content-center bg-blue-500 border-round card-icon mr-2';
    const columnVehicleTypeClasses = 'flex justify-content-center mr-4';

    const formatValue = (value: number): string => {
        if (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }

        return "0";
    }

    const renderVehicleTypeValues = (value: number) => {
        return props.isLoading ? renderSkeletonVehicleTypeValue :
            <div className="text-900 text-3xl">
                {formatValue(value)}
            </div>
    }

    return (
        <div className="col-12 lg:col-12 xl:col-12">
            <div className="b-0">
                <Fieldset legend="Veículos Detectados" toggleable>
                    <div className="flex justify-content-between">
                        <div className={columnVehicleTypeClasses}>
                            <div className={iconsClasses}>
                                <FontAwesomeIcon icon={faMotorcycle} size={"xl"} />
                            </div>
                            <div>
                                <span className={textClasses}>Motos detectadas</span>
                                {renderVehicleTypeValues(props.cardResponse.quantityMotorBike)}
                            </div>
                        </div>
                        <div className={columnVehicleTypeClasses}>
                            <div className={iconsClasses}>
                                <FontAwesomeIcon icon={faCar} size={"xl"} />
                            </div>
                            <div>
                                <span className={textClasses}>Carros Detectados</span>
                                {renderVehicleTypeValues(props.cardResponse.quantityCar)}
                            </div>
                        </div>
                        <div className={columnVehicleTypeClasses}>
                            <div className={iconsClasses}>
                                <FontAwesomeIcon icon={faTruckPickup} size={"xl"} />
                            </div>
                            <div>
                                <span className={textClasses}>Pickups Detectadas</span>
                                {renderVehicleTypeValues(props.cardResponse.quantityPickup)}
                            </div>
                        </div>
                        <div className={columnVehicleTypeClasses}>
                            <div className={iconsClasses}>
                                <FontAwesomeIcon icon={faBus} size={"xl"} />
                            </div>
                            <div>
                                <span className={textClasses}>Ônibus Detectados</span>
                                {renderVehicleTypeValues(props.cardResponse.quantityBus)}
                            </div>
                        </div>
                        <div className={columnVehicleTypeClasses}>
                            <div className={iconsClasses}>
                                <FontAwesomeIcon icon={faTruck} size={"xl"} />
                            </div>
                            <div>
                                <span className={textClasses}>Caminhões Detectados</span>
                                {renderVehicleTypeValues(props.cardResponse.quantityTruck)}
                            </div>
                        </div>
                        {/* <div className={columnVehicleTypeClasses}>
                            <div className={iconsClasses}>
                                <FontAwesomeIcon icon={faTruck} size={"xl"} />
                            </div>
                            <div>
                                <span className={textClasses}>Tratores Detectados</span>
                                {renderVehicleTypeValues(props.cardResponse.quantityMachinery)}
                            </div>
                        </div> */}
                        <div className={columnVehicleTypeClasses}>
                            <div className={iconsClasses}>
                                <FontAwesomeIcon icon={faQuestion} size={"xl"} />
                            </div>
                            <div>
                                <span className={textClasses}>Não Identificados</span>
                                {renderVehicleTypeValues(props.cardResponse.quantityOther)}
                            </div>
                        </div>
                    </div>
                </Fieldset>

            </div>
        </div >
    );
}
