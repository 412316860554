import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import { DestinationABService } from "../../../services/destination-ab-service";
import { destinationABInterface, emptyDestinationAB } from "../types/types";
import { CreateDestinationABDialog } from "./dialogs/create-destination-ab-dialog";
import { DeleteDestinationABDialog } from "./dialogs/delete-destination-ab-dialog";
import { DeleteDestinationsABDialog } from "./dialogs/delete-destinations-ab-dialog";
import { DestinationABListDataTable } from "./data-tables/destination-ab-list-data-table";
import { AuthServices } from "../../../services/utils/auth-services";


export const DestinationAB = () => {
    const _toastService = new ToastServices();
    const _authServices = new AuthServices();
    const [destinationsAB, setDestinationsAB] = useState([]);
    const [destinationAB, setDestinationAB] = useState<destinationABInterface>(emptyDestinationAB);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [destinationABDialog, setDestinationABDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedDestinationsAB, setSelectedDestinationsAB] = useState(null);
    const [deleteDestinationABDialog, setDeleteDestinationABDialog] = useState(false);
    const [deleteDestinationsABDialog, setDeleteDestinationsABDialog] = useState(false);
    const [dialogHeader, setDialogHeader] = useState<string>(null);
    const [isEditingDestinationAB, setIsEditingDestinationAB] = useState<boolean>(null);
    const destinationABService = new DestinationABService();

    _authServices.HandleAppAuth();

    useEffect(() => {
        getDestinationsAB();
    }, []);

    const getDestinationsAB = () => {
        setLoading(true);
        destinationABService.getDestinationsAB().then(
            (response) => {
                setDestinationsAB(response.data);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                _toastService.showErrorRequestMessage(error);
            }
        );
    }

    const openNew = () => {
        setDestinationAB(emptyDestinationAB);
        setDialogHeader("Cadastrar Novo Ponto A & B");
        setIsEditingDestinationAB(false);
        setDestinationABDialog(true);
        setSubmitted(false);
    }

    const hideDialog = () => {
        setDestinationABDialog(false);
        setLoading(false);
        setDestinationAB(emptyDestinationAB);
        setSubmitted(false);
    }

    const editDestinationAB = (destinationAB: destinationABInterface) => {
        setDestinationAB(destinationAB);
        setDialogHeader("Editar Ponto A & B");
        setDestinationABDialog(true);
        setIsEditingDestinationAB(true);
        setSubmitted(false);
    }

    const confirmDeleteDestinationAB = (destinationAB: destinationABInterface) => {
        setDestinationAB(destinationAB);
        setDeleteDestinationABDialog(true);
    }

    const confirmDeleteSelected = () => {
        setDeleteDestinationsABDialog(true);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedDestinationsAB || !selectedDestinationsAB.length} />
                </div>
            </React.Fragment>
        );
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <React.Fragment>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" value={globalFilter} onInput={(e: any) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
                    </span>
                </React.Fragment>
            </>
        );
    }

    const onSubmit = () => {
        setSubmitted(true);
        if (isDestinatioABValid()) {

            if (destinationAB.id) {
                destinationABService.updateDestinationAB(destinationAB).then(
                    () => {
                        _toastService.showSuccessMessage("Ponto A & B atualizado com sucesso.");
                        getDestinationsAB();
                    },
                    (error) => {
                        _toastService.showErrorMessage(error.response.data);
                        getDestinationsAB();
                    });
            } else {
                destinationABService.addDestinationAB(destinationAB).then(
                    () => {
                        _toastService.showSuccessMessage("Ponto A & B Cadastrado com Sucesso.");
                        getDestinationsAB();
                    },
                    (error) => {
                        _toastService.showErrorMessage(error.response.data);
                        getDestinationsAB();
                    });
            }
            setDestinationAB(emptyDestinationAB);
            setDestinationABDialog(false);
        }
        setLoading(false);
    }

    const isDestinatioABValid = () => {
        return destinationAB.cameraPointA.id !== 0 && destinationAB.cameraPointB.id !== 0;
    }

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <PageHeader title="CONFIGURAÇÃO DESLOCAMENTO ESPECIAL" icon="pi pi-chart-bar" />
                <ToastContainer />
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DestinationABListDataTable
                        destinationsAB={destinationsAB}
                        selectedDestinationsAB={selectedDestinationsAB}
                        setSelectedDestinationnAB={setSelectedDestinationsAB}
                        loading={loading}
                        globalFilter={globalFilter}
                        editDestinationAB={editDestinationAB}
                        confirmDeleteDestinationAB={confirmDeleteDestinationAB}
                    />

                    <CreateDestinationABDialog
                        destinationAB={destinationAB}
                        setDestinationAB={setDestinationAB}
                        setDestinationABDialog={setDestinationABDialog}
                        onSubmit={onSubmit}
                        setSubmitted={setSubmitted}
                        isEditingDestinationAB={isEditingDestinationAB}
                        visible={destinationABDialog}
                        hideDialog={hideDialog}
                        submitted={submitted}
                        dialogHeader={dialogHeader}
                        setLoading={setLoading}
                    />

                    <DeleteDestinationABDialog
                        setLoading={setLoading}
                        emptyDestionationAB={emptyDestinationAB}
                        visible={deleteDestinationABDialog}
                        setDeleteDestionationABDialog={setDeleteDestinationABDialog}
                        destinationAB={destinationAB}
                        setDestinationAB={setDestinationAB}
                        getDestinationsAB={getDestinationsAB} />

                    <DeleteDestinationsABDialog
                        setLoading={setLoading}
                        getDestinationsAB={getDestinationsAB}
                        selectedDestinationsAB={selectedDestinationsAB}
                        destinationAB={destinationAB}
                        setDeleteDestinationsABDialog={setDeleteDestinationsABDialog}
                        setSelectedDestinationsAB={setSelectedDestinationsAB}
                        visible={deleteDestinationsABDialog} />
                </div>
            </div>
        </div>
    )
};
