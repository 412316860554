import { Dialog } from 'primereact/dialog';
import React from 'react';
import { MachineryVehicleAlertResponse } from '../../../../models/types';

export interface MachineryVehicleAlertDialogProps {
    machineryVehicleAlertDialog: boolean,
    machineryVehicleAlert: MachineryVehicleAlertResponse,
    setMachineryVehicleAlertDialog: () => void
}

export const MachineryVehicleAlertDialog = (props: MachineryVehicleAlertDialogProps) => {
    return (
        <Dialog visible={props.machineryVehicleAlertDialog} style={{ width: "600px" }} header="ALERTA MAQUINÁRIO" modal onHide={() => props.setMachineryVehicleAlertDialog()}>
                    <div className="confirmation-content">
                        <div className="col-12">
                            <div style={{ display: `flex` }}>
                                <h5 style={{ fontWeight: `bold`, width: `50%`, margin: `0 0 0 0`, padding: `0px` }}>Placa: {props.machineryVehicleAlert.plate}</h5>
                                <h5 style={{ fontWeight: `bold`, width: `50%`, margin: `0 0 0 0`, padding: `0px` }}>Data: {props.machineryVehicleAlert.dateTime}</h5>
                            </div>
                            <div style={{ display: `flex` }}>
                                <h5 style={{ width: `50%`, margin: `10px 0 0 0`, padding: `0px` }}>Camera: {props.machineryVehicleAlert.cameraName}</h5>
                                <h5 style={{ width: `50%`, margin: `10px 0 0 0`, padding: `0px` }}>Razão: {props.machineryVehicleAlert.reason}</h5>
                            </div>
                            <div style={{ display: `flex` }}>
                                <h5 style={{ width: `100%`, margin: `10px 0 0 0`, padding: `0px` }}>
                                    Endereço: {props.machineryVehicleAlert.address} { }
                                </h5>
                            </div>
                            <div style={{ display: `flex` }}>
                                <h5 style={{ width: `100%`, margin: `10px 0 0 0`, padding: `0px`, display: `${props.machineryVehicleAlert.description ? `inherit` : `none`}` }}>Descrição: {props.machineryVehicleAlert.description}</h5>
                            </div>
                            <div></div>
                        </div>
                        <div className="col-12">
                            <img src={props.machineryVehicleAlert.imageUrl} style={{ width: `100%`, height: `30rem`, borderRadius: `5px` }} />
                        </div>
                    </div>
                </Dialog>
    );
}
