import React from "react"
import { cardResponse } from "../../../../models/types";
import { Skeleton } from "primereact/skeleton";
import { Fieldset } from "primereact/fieldset";

export interface EventsCardsDataProps {
    renderCardValues: (value: any) => JSX.Element,
    cardResponse: cardResponse;
    isLoading: boolean;
}

export const EventsCardsData = (props: EventsCardsDataProps) => {
    const textClasses = 'flex text-500 font-medium mb-1';
    const renderSkeletonVehicleTypeValue = <Skeleton shape="rectangle" width='100px' height='30px' />;
    const iconsClasses = 'flex align-items-center justify-content-center bg-blue-500 border-round card-icon mr-2';
    const columnVehicleTypeClasses = 'flex justify-content-center mr-4';

    const renderVehicleTypeValues = (value: number) => {
        return props.isLoading ? renderSkeletonVehicleTypeValue :
            <div className="text-900 text-3xl">
                {value ?? 0}
            </div>
    }

    return (
        <div className="col-12 lg:col-12 xl:col-12">
            <Fieldset legend="Eventos Disparados" toggleable>
                <div className="flex justify-content-between grid">
                    <div className={columnVehicleTypeClasses}>
                        <div className={iconsClasses}>
                            <i className="pi pi-fw pi-chart-bar text-blue-50 text-xl" />
                        </div>
                        <div>
                            <span className={textClasses}>A.C.M</span>
                            {renderVehicleTypeValues(props.cardResponse.quantityOfBikerBehaviorsTriggeredEvents)}
                        </div>
                    </div>
                    <div className={columnVehicleTypeClasses}>
                        <div className={iconsClasses}>
                            <i className="pi pi-fw pi-chart-bar text-blue-50 text-xl" />
                        </div>
                        <div>
                            <span className={textClasses}>Alarme Customizado</span>
                            {renderVehicleTypeValues(props.cardResponse.quantityOfCustomAlarmTriggeredEvents)}
                        </div>
                    </div>
                    <div className={columnVehicleTypeClasses}>
                        <div className={iconsClasses}>
                            <i className="pi pi-fw pi-chart-bar text-blue-50 text-xl" />
                        </div>
                        <div>
                            <span className={textClasses}>Sem Placa</span>
                            {renderVehicleTypeValues(props.cardResponse.quantityOfNoPlateTriggeredEvents)}
                        </div>
                    </div>
                    <div className={columnVehicleTypeClasses}>
                        <div className={iconsClasses}>
                            <i className="pi pi-fw pi-chart-bar text-blue-50 text-xl" />
                        </div>
                        <div>
                            <span className={textClasses}>Black List</span>
                            {renderVehicleTypeValues(props.cardResponse.quantityOfBlackListTriggeredEvents)}
                        </div>
                    </div>
                    <div className={columnVehicleTypeClasses}>
                        <div className={iconsClasses}>
                            <i className="pi pi-fw pi-chart-bar text-blue-50 text-xl" />
                        </div>
                        <div>
                            <span className={textClasses}>Deslocamento Especial</span>
                            {renderVehicleTypeValues(props.cardResponse.quantityOfDestinationABTriggeredEvents)}
                        </div>
                    </div>
                    <div className={columnVehicleTypeClasses}>
                        <div className={iconsClasses}>
                            <i className="pi pi-fw pi-chart-bar text-blue-50 text-xl" />
                        </div>
                        <div>
                            <span className={textClasses}>Modo Comboio</span>
                            {renderVehicleTypeValues(props.cardResponse.quantityOfConvoyTriggeredEvents)}
                        </div>
                    </div>
                    <div className={columnVehicleTypeClasses}>
                        <div className={iconsClasses}>
                            <i className="pi pi-fw pi-chart-bar text-blue-50 text-xl" />
                        </div>
                        <div>
                            <span className={textClasses}>Modo Comboio Comportamental</span>
                            {renderVehicleTypeValues(props.cardResponse.quantityOfBehavioralConvoyTriggeredEvents)}
                        </div>
                    </div>
                    <div className={columnVehicleTypeClasses}>
                        <div className={iconsClasses}>
                            <i className="pi pi-fw pi-chart-bar text-blue-50 text-xl" />
                        </div>
                        <div>
                            <span className={textClasses}>Maquinário</span>
                            {renderVehicleTypeValues(props.cardResponse.quantityOfMachineryTriggeredEvents)}
                        </div>
                    </div>
                </div>
            </Fieldset>
        </div >
    )
}
