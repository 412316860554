import { useHistory } from "react-router";
export class AuthServices {

    HandleAppAuth = (): void => {
        const history = useHistory();
        if (!sessionStorage.getItem("Bearer")) {
            sessionStorage.clear();
            history.push('/login');
        }
    }

    GetUserToken = (): string => {
        return sessionStorage.getItem("Bearer");
    }

    IsAdmin = (): string => {
        return sessionStorage.getItem("Bearer");
    }
}
