import React from 'react';
import { MachineryVehicleConfiguration } from '../../types/types';
import { Button } from 'primereact/button';
import { WhatsappGroup } from '../../../../components/WhatsappGroup/whatsapp-group';
import { Fieldset } from 'primereact/fieldset';

export interface WhatsappMachineryVehicleConfigProps {
    saveConfig: () => void,
    machineryVehicleConfig: MachineryVehicleConfiguration,
    setMachineryVehicleConfig: (object: any) => void
}
export const WhatsappMachineryVehicleConfig = (props: WhatsappMachineryVehicleConfigProps) => {
    const header = <>
        <span className="titulo-categoria">
            <i className='pi pi-whatsapp mr-2'></i>
            Configuração Notificação via Whatsapp
        </span>
    </>;

    return (
        <>
            <Fieldset legend={header} className='mb-4'>
                <WhatsappGroup
                    selectedLocation={props.machineryVehicleConfig.locationId}
                    selectedGroupId={props.machineryVehicleConfig.whatsappGroupId}
                    object={props.machineryVehicleConfig}
                    objectUpdate={props.setMachineryVehicleConfig}
                />
                <Button className="mt-4"
                    label="SALVAR CONFIGURAÇÃO"
                    onClick={(e) => {
                        props.saveConfig()
                    }}
                />
            </Fieldset>

        </>
    )
}
