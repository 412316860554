import { Button } from "primereact/button";
import { DataView } from 'primereact/dataview';
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import { ImageViewer } from "../../../components/ImageViewer/image-viewer";
import { DropdownInfo } from "../../../models/types";
import { CameraServices } from "../../../services/camera-services";
import { MachineryVehicleServices } from "../../../services/machinery-vehicle-services";
import { FeatureServices } from "../../../services/feature-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import '../styles/machinery-vehicle.scss';
import { MachineryVehicleModeDataResponse } from "../types/types";
import { NavigateToLocation } from "../../../components/Navigation/navigate-to-location";

export const EventMachineryVehicle = () => {
    const _machineryVehicleServices = new MachineryVehicleServices();
    const _cameraServices = new CameraServices();
    const _featureServices = new FeatureServices();
    const _toastServices = new ToastServices();

    const [cameras, setCameras] = useState<DropdownInfo[]>();
    const [images, setImages] = useState<MachineryVehicleModeDataResponse[]>([]);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [selectedCamera, setSelectedCamera] = useState<number>(0);
    const [loadingImages, setLoadingImages] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(true);

    useEffect(() => {
        loadLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadLocations = () => {
        let result: DropdownInfo[] = [];
        result.push({ id: 0, description: "TODAS LOCALIDADES" });
        setLoadingLocations(true);
        _featureServices.getLocationsByFeature('MACHINERY-ALARM')
            .then(data => {
                data.data.forEach((location: any) => {
                    result.push(location);
                });
                setLocations(result);
                setLoadingLocations(false);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
            })
    }

    const getVehicleImages = () => {
        setLoadingImages(true);
        _machineryVehicleServices.getMachineryVehicleEvents(selectedCamera, selectedLocation)
            .then((data: any) => {
                setImages(data.data);
                setLoadingImages(false);
            });
    }

    const onSelectedUF = (e: { value: any }) => {
        setSelectedLocation(e.value);

        let response: DropdownInfo[] = _cameraServices.getCamerasByLocation(e.value);
        setSelectedCamera(0);
        setCameras(response);
    }

    const onSelectedCamera = (e: { value: any }) => {
        setSelectedCamera(e.value);
    }

    const renderSearchFilters = () => {
        return (

            <div className="grid">
                <div className="col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-map-marker"></i>
                        </span>
                        <Dropdown
                            options={locations}
                            placeholder='Selecione uma localidade'
                            className="mr-2"
                            optionLabel="description"
                            optionValue="id"
                            value={selectedLocation}
                            disabled={loadingLocations}
                            onChange={(e) => onSelectedUF(e)}>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-2">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-camera"></i>
                        </span>
                        <Dropdown
                            options={cameras}
                            placeholder='Camera'
                            className="mr-2"
                            optionLabel="description"
                            optionValue="id"
                            value={selectedCamera}
                            disabled={selectedLocation === 0}
                            onChange={(e) => onSelectedCamera(e)}>
                        </Dropdown>
                    </div>
                </div>
                <div className="col-3">
                    <Button
                        label="Pesquisar"
                        disabled={loadingImages}
                        onClick={(e) => getVehicleImages()}>
                    </Button>
                </div>
            </div>

        )
    }

    const renderSkeletonDataScroller = () => {
        let responseArray: any[] = [];

        for (let i = 0; i < 6; i++) {
            responseArray.push(skeletonGridItem());
        }

        return (
            <div className="p-dataview p-component p-dataview-grid">
                <div className="p-dataview-content">
                    <div className="flex">
                        {responseArray}
                    </div>
                </div>
            </div>
        )
    }

    const skeletonGridItem = () => {
        return (
            <div className="col-12 sm:col-4 lg:col-12 xl:col-2 p-2">
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                        <Skeleton className="w-6rem border-round h-1rem" />
                    </div>
                    <div className="flex flex-column align-items-center gap-3 py-5">
                        <Skeleton className="w-6 shadow-2 border-round h-10rem" />
                        <Skeleton className="w-8rem border-round h-2rem" />
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <Skeleton className="w-4rem border-round h-2rem" />
                        <Skeleton shape="circle" className="w-3rem h-3rem" />
                    </div>
                </div>
            </div>
        );
    }

    const gridItem = (event: MachineryVehicleModeDataResponse) => {
        return (
            <div className="col-12 sm:col-12 lg:col-6 xl:col-2 p-2">
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="gap-2">
                        <div className="flex align-items-center gap-2">
                            <i className="pi pi-camera"></i>
                            <span className="product-category font-semibold">{event.camera}</span>
                        </div>
                    </div>
                    <div className="flex justify-content-evenly gap-2">
                        <div className="gap-3 py-10">
                            <div className="grid mt-1">
                                <div className='col-12'>
                                    <ImageViewer
                                        alt={event.plate}
                                        src={event.imageUrl}
                                        style={{
                                            width: '90%',
                                            height: '200px',
                                            border: '1px solid var(--text-color)',
                                            borderRadius: '15px',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </div>
                                <div className='col-12 sm:col-6 lg:col-12 xl:col-12 p-2'>
                                    <strong>
                                        <span className="pi pi-tag mr-1"></span>
                                        <span className="mr-2">PLACA VEÍCULO:</span>
                                        {event.plate}
                                    </strong>
                                </div>
                                <div className='col-12 sm:col-6 lg:col-6 xl:col-12 p-2'>
                                    <span className="mr-2 pi pi-clock"></span>
                                    {event.createStamp}
                                </div>
                                <div className='col-12 sm:col-12 lg:col-6 xl:col-12 p-2' style={{ wordBreak: 'break-all' }}>
                                    <span className="pi pi-info mr-1"></span>
                                    <span className="mr-2">Localização:</span>
                                    {event.cameraAddress}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    };


    const itemTemplate = (event: MachineryVehicleModeDataResponse) => {
        if (!event) {
            return;
        }

        return gridItem(event);
    }

    const renderDataScroller = () => {
        return (
            <DataView
                value={images}
                itemTemplate={itemTemplate}
                layout='grid'
                emptyMessage="Nenhuma imagem encontrada para o filtro selecionado"
                rows={12}
                paginator>
            </DataView>
        )
    }

    return (
        <div className='grid'>
            <div className='col-12'>
                <PageHeader
                    title="EVENTOS DE MAQUINÁRIOS"
                    icon="pi pi-chart-bar"
                />
                <div className='card'>
                    <NavigateToLocation
                        icon="pi-spin pi-cog"
                        text="CONFIGURAR MAQUINÁRIOS"
                        to="machinery-vehicle-configuration"
                    />
                    {renderSearchFilters()}
                    {loadingImages ? renderSkeletonDataScroller() : renderDataScroller()}
                </div>
            </div>
        </div>
    )
}
