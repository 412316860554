import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { PageHeader } from "../../../views/page-header";
import { emptyDSMApplication, dsmApplication } from "../types/types";
import { AuthServices } from "../../../services/utils/auth-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { DSMApplicationServices } from "../../../services/dsm-application-services";
import { CreateDSMApplicationDialog } from "./dialogs/create-dsm-application-dialog";
import { DeleteDSMApplicationDialog } from "./dialogs/delete-dsm-application-dialog";
import { DeleteDSMApplicationsDialog } from "./dialogs/delete-dsm-applications-dialog";
import { DSMApplicationListDataTable } from "./data-tables/dsm-application-list-data-table";

export const DSMApplication = () => {
    const _toastServices = new ToastServices();
    const _authServices = new AuthServices();
    const _dsmApplicationServices = new DSMApplicationServices();

    const [dsmApplications, setDSMApplications] = useState([]);
    const [dsmApplication, setDSMApplication] = useState<dsmApplication>(emptyDSMApplication);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dsmApplicationDialog, setDSMApplicationDialog] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedDSMApplications, setSelectedDSMApplications] = useState<dsmApplication[]>([]);
    const [deleteDSMApplicationDialog, setDeleteDSMApplicationDialog] = useState(false);
    const [deleteDSMApplicationsDialog, setDeleteDSMApplicationsDialog] = useState(false);
    const [dialogHeader, setDialogHeader] = useState<string>(null);

    const [isEditingDSMApplication, setIsEditingDSMApplication] = useState<boolean>(null);

    _authServices.HandleAppAuth();

    useEffect(() => {
        getDSMApplications();
    }, []);

    const getDSMApplications = () => {
        setLoading(true);
        _dsmApplicationServices.getDSMApplications().then(
            (response) => {
                setDSMApplications(response.data);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                _toastServices.showErrorRequestMessage(error);
            }
        );
    }

    const openNew = () => {
        setDSMApplication(emptyDSMApplication);
        setDialogHeader("Cadastrar Novo Servidor DSM");
        setIsEditingDSMApplication(false);
        setDSMApplicationDialog(true);
        setSubmitted(false);
    }

    const hideDialog = () => {
        setDSMApplicationDialog(false);
        setLoading(false);
        setDSMApplication(emptyDSMApplication);
        setSubmitted(false);
    }

    const editDSMApplication = (dsmApplication: dsmApplication) => {
        setDSMApplication(dsmApplication);
        setDialogHeader("Editar Servidor DSM");
        setIsEditingDSMApplication(true);
        setDSMApplicationDialog(true);
        setSubmitted(false);
    }

    const confirmDeleteDSMApplication = (dsmApplication: dsmApplication) => {
        setDSMApplication(dsmApplication);
        setDeleteDSMApplicationDialog(true);
    }

    const confirmDeleteSelected = () => {
        setDeleteDSMApplicationsDialog(true);
    }

    const onSubmit = () => {
        setLoading(true);
        setSubmitted(true);

        if (isDSMApplicationValid()) {

            if (dsmApplication.dsmApplicationId) {
                _dsmApplicationServices.updateDSMApplication(dsmApplication).then(
                    () => {
                        _toastServices.showSuccessMessage("Servidor DSM atualizado com sucesso.");
                        getDSMApplications();
                    },
                    (error) => _toastServices.showErrorRequestMessage(error));
            } else {
                _dsmApplicationServices.addDSMApplication(dsmApplication).then(
                    () => {
                        _toastServices.showSuccessMessage("Servidor DSM Cadastrado com Sucesso.");
                        getDSMApplications();
                    },
                    (error) => _toastServices.showErrorRequestMessage(error));
            }
            setDSMApplication(emptyDSMApplication);
            setDSMApplicationDialog(false);
        } else {
            _toastServices.showErrorRequestMessage("DSM inválido");
        }
        setLoading(false);
    }

    const updateHash = async () => {
        setLoading(true);
        setSubmitted(true);

        if (dsmApplication.dsmApplicationId) {
            await _dsmApplicationServices.updateHashDSMApplication(dsmApplication.dsmApplicationId).then(
                () => {
                    _toastServices.showSuccessMessage("Hash atualizado com sucesso.");
                    getDSMApplications();
                },
                (error: any) => _toastServices.showErrorRequestMessage(error));
        }

        setLoading(false);
    }


    const isDSMApplicationValid = () => {
        return dsmApplication.dsmApplicationName && dsmApplication.locationId && dsmApplication.type && dsmApplication.modo
    }

    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader title="CADASTRO SERVIDORES DSM"
                    icon="pi pi-server"
                />
                <div className="card">
                    <DSMApplicationListDataTable
                        confirmDeleteSelected={confirmDeleteSelected}
                        openNew={openNew}
                        setGlobalFilter={setGlobalFilter}
                        dsmApplications={dsmApplications}
                        selectedDSMApplications={selectedDSMApplications}
                        setSelectedDSMApplications={setSelectedDSMApplications}
                        loading={loading}
                        globalFilter={globalFilter}
                        editDSMApplication={editDSMApplication}
                        confirmDeleteDSMApplication={confirmDeleteDSMApplication}
                    />
                    <CreateDSMApplicationDialog
                        dsmApplication={dsmApplication}
                        setDSMApplication={setDSMApplication}
                        setDSMApplicationDialog={setDSMApplicationDialog}
                        onSubmit={onSubmit}
                        updateHash={updateHash}
                        setSubmitted={setSubmitted}
                        isEditingDSMApplication={isEditingDSMApplication}
                        visible={dsmApplicationDialog}
                        hideDialog={hideDialog}
                        submitted={submitted}
                        dialogHeader={dialogHeader}
                    />
                    <DeleteDSMApplicationDialog
                        dsmApplication={dsmApplication}
                        setDSMApplication={setDSMApplication}
                        getDSMApplications={getDSMApplications}
                        emptyDSMApplication={emptyDSMApplication}
                        setLoading={setLoading}
                        visible={deleteDSMApplicationDialog}
                        setDeleteDSMApplicationDialog={setDeleteDSMApplicationDialog}
                    />
                    <DeleteDSMApplicationsDialog
                        getDSMApplications={getDSMApplications}
                        setLoading={setLoading}
                        visible={deleteDSMApplicationsDialog}
                        selectedDSMApplications={selectedDSMApplications}
                        setSelectedDSMApplications={setSelectedDSMApplications}
                        setDeleteDSMApplicationsDialog={setDeleteDSMApplicationsDialog}
                        dsmApplication={dsmApplication}
                    />
                </div>
            </div>
        </div>
    )
}
