import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from "primereact/password";
import { Timeline } from 'primereact/timeline';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { DssServices } from '../../../../services/dss-services';
import { onInputChange } from '../../../../services/utils/input-services';
import { ToastServices } from '../../../../services/utils/toast-services';
import { DssModel, TimelineEvent, blankDss } from '../../models/types';
import { ClipLoader } from 'react-spinners';

export interface CreateDssProps {
    loading: boolean,
    selectedLocation: number,
    setLoading: (val: boolean) => void,
}

export const CreateDss = (props: CreateDssProps) => {
    const _dssServices = new DssServices();
    const _toastServices = new ToastServices();

    const events: TimelineEvent[] = _dssServices.getTimeLineEvents();

    const [submitted, setSubmitted] = useState<boolean>(false);
    const [dssModel, setDssModel] = useState<DssModel>(blankDss);
    const [dssModelStatus, setDssModelStatus] = useState<number>(-1);
    const [loadingTimeLine, setLoadingTimeLine] = useState<boolean>(false);

    useEffect(() => {
        if (props.selectedLocation !== 0) {
            setDssModelStatus(-1);
            setDssModel(blankDss);
            getLocationDss();

            var _newDss: DssModel = { ...dssModel };

            _newDss.locationId = props.selectedLocation;
            _newDss.password = '';

            setDssModel(_newDss);
        }
    }, [props.selectedLocation])


    const getLocationDss = () => {
        props.setLoading(true);

        _dssServices.getLocationDss(props.selectedLocation)
            .then(data => {
                if (data.status === 200) {
                    setDssModel(data.data);
                    setDssModelStatus(1);
                }
            })
            .catch(error => {
                if (error.response.status !== 404) {
                    _toastServices.showErrorRequestMessage(error);
                }
            });
        props.setLoading(false);
    }

    const onSave = () => {
        setSubmitted(true);

        if (!_dssServices.validateDssModel(dssModel)) {
            _toastServices.showErrorMessage("Preencha todos os campos!");
            return;
        }

        setDssModelStatus(-1);
        setLoadingTimeLine(true);

        _toastServices.showSuccessMessage("Inciando sincronismo com DSS Client...");

        setTimeout(() => {
            _toastServices.showSuccessMessage("Tentando estabelecer conexão com DSS...");

            _dssServices.saveLocationDss(dssModel)
                .then(data => {
                    setDssModelStatus(1);
                    _toastServices.showSuccessMessage("DSS Criado e Conexão estabelecida com sucesso.");
                    onRequestedEventsSync();
                })
                .catch(error => {
                    _toastServices.showErrorRequestMessage(error);
                    setLoadingTimeLine(false);
                });;
        }, 500);

        cleanPasswordField();
        setSubmitted(false);
    }
    const onRequestedEventsSync = () => {
        setTimeout(() => {
            _toastServices.showSuccessMessage("Iniciando sincronismo de eventos");

            _dssServices.syncDssClientEvents(props.selectedLocation)
                .then(data => {
                    setDssModelStatus(2);
                    _toastServices.showSuccessMessage("Eventos DSS Client sincronizados com sucesso");
                    onRequestedCamerasSync();
                })
                .catch(error => {
                    _toastServices.showErrorRequestMessage(error);
                    setLoadingTimeLine(true);
                });
        }, 1000);
    }
    const onRequestedCamerasSync = () => {
        setTimeout(() => {
            _toastServices.showSuccessMessage("Iniciando sincronismo de câmeras");

            _dssServices.syncDssClientCameras(props.selectedLocation)
                .then(data => {
                    setDssModelStatus(5);
                    _toastServices.showSuccessMessage(data.data);
                    setLoadingTimeLine(false);
                    _toastServices.showSuccessMessage("Sincronismo com DSS Cliente Finalizado!");
                })
                .catch(error => {
                    setLoadingTimeLine(false);
                    _toastServices.showErrorRequestMessage(error);
                });
        }, 1000);

    }
    const onDelete = () => {
        _dssServices.deleteLocationDss(props.selectedLocation)
            .then(data => {
                setDssModelStatus(-1);
                _toastServices.showSuccessMessage("DSS Client excluído com sucesso");
            })
            .catch(error => {
                if (error.response.status !== 404) {
                    _toastServices.showErrorRequestMessage(error);
                }
            });
    }
    const onResync = () => {
        _toastServices.showSuccessMessage("Resincronizando DSS Client");

        onRequestedEventsSync();
    }

    const cleanPasswordField = () => {
        var _newDss: DssModel = { ...dssModel };

        _newDss.password = '';

        setDssModel(_newDss);
    }
    const markerTimelineTemplate = (item: any) => {
        return <div className='flex' style={{ alignItems: 'center', justifyContent: 'center', height: '100%', padding: '5px', border: `2px solid ${getColorForTimeLineItem(item)}`, borderRadius: '15px' }}>
            <span className={item.icon} style={{ display: 'flex', alignItems: 'center', color: getColorForTimeLineItem(item), fontSize: '1.5rem' }}></span>
        </div>
    }
    const getColorForTimeLineItem = (item: any) => {
        var currentIndex: number = events.findIndex(x => x.status === item.status);

        return currentIndex <= dssModelStatus ? '#32CD32' : 'var(--text-color)';
    }

    return (
        <React.Fragment>
            {props.selectedLocation !== 0 && !props.loading &&
                <React.Fragment>
                    <div className='grid mt-3'>
                        <div className="col-12 categoria">
                            <div className='grid'>
                                <div className='col-12'>
                                    <span className="titulo-categoria">DADOS DE ACESSO DSS CLIENT:</span>
                                </div>
                                <div className='col-7'>
                                    <strong>URL:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-globe"></i>
                                        </span>
                                        <InputText
                                            value={dssModel.url}
                                            onChange={(e) => onInputChange(e, 'url', dssModel, setDssModel)}
                                            className={classNames({ "p-invalid": submitted && !dssModel.url })}
                                        />
                                    </div>
                                </div>
                                <div className='col-1'>
                                    <strong>PORTA:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-globe"></i>
                                        </span>
                                        <InputText
                                            value={dssModel.port}
                                            type='number'
                                            onChange={(e) => onInputChange(e, 'port', dssModel, setDssModel)}
                                        />
                                    </div>
                                </div>
                                <div className='col-1'/>
                                <div className='col-4'>
                                    <strong>Usuário:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-user"></i>
                                        </span>
                                        <InputText
                                            value={dssModel.user}
                                            onChange={(e) => onInputChange(e, 'user', dssModel, setDssModel)}
                                            className={classNames({ "p-invalid": submitted && !dssModel.user })}
                                        />
                                    </div>

                                </div>
                                <div className='col-4'>
                                    <strong>Senha:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-lock"></i>
                                        </span>
                                        <Password
                                            value={dssModel.password}
                                            onChange={(e) => onInputChange(e, 'password', dssModel, setDssModel)}
                                            className={classNames({ "p-invalid": submitted && !dssModel.password })}
                                            required
                                            autoFocus
                                            feedback={false}
                                            toggleMask
                                        />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <Button
                                        onClick={(e) => onSave()}
                                        disabled={props.loading}
                                        label='Salvar'
                                    />
                                    <Button
                                        className='ml-2'
                                        disabled={dssModel.locationDssId === 0}
                                        severity='danger'
                                        onClick={(e) => onDelete()} label='Excluir'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 categoria ml-2">
                            <div className='flex'>
                                <span className="titulo-categoria">STATUS DSS CLIENT:</span>
                                <ClipLoader className='ml-3' color='var(--text-color)' loading={loadingTimeLine} size={15} />
                            </div>
                            <div className='flex' style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Timeline
                                    style={{ display: 'flex', alignItems: 'center', height: '100%' }}
                                    value={events}
                                    align='top'
                                    layout="horizontal"
                                    content={(item) => item.status}
                                    marker={(item) => markerTimelineTemplate(item)}
                                />
                            </div>

                        </div>
                        <div className='col-12'>
                            <Button disabled={dssModel.locationDssId === 0} severity='warning' className='mb-2' label='Resincronizar DSS Client' onClick={(e) => onResync()}></Button>
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    )
}
