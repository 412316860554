import { Button } from 'primereact/button';
import { Menubar } from 'primereact/menubar';
import React from 'react';
import { useHistory } from 'react-router';
import blackLogo from './wwroot/img/moon-black-logo.png';
import whiteLogo from './wwroot/img/moon-white-logo.png';

export const AppTopbar = (props) => {
    const history = useHistory();

    const getMenuItems = () => {
        let response = [];

        response.push({
            label: "Dashboard",
            icon: "pi pi-fw pi-home",
            command: (event) => {
                history.push('/');
            }
        });

        if (sessionStorage.getItem("IsAdministrator") === "True") {
            response.push({
                label: "Cadastros",
                icon: "pi pi-fw pi-sitemap",
                items: [
                    {
                        label: "Usuários",
                        icon: "pi pi-fw pi-id-card",
                        to: "usuario",
                        command: (event) => {
                            history.push(event.item.to);
                        }
                    },
                    {
                        label: "Câmeras",
                        icon: "pi pi-fw pi-camera",
                        to: "camera",
                        command: (event) => {
                            history.push(event.item.to);
                        }
                    },
                    {
                        label: "Servidores",
                        icon: "pi pi-fw pi-chart-line",
                        to: "dsm-application",
                        command: (event) => {
                            history.push(event.item.to);
                        }
                    }
                ],
            });
        }

        response.push({
            label: "Recursos",
            icon: "pi pi-ticket",
            items: [
                {
                    label: "A.C.M",
                    icon: "pi pi-fw pi-chart-bar",
                    to: "event-biker-behavior",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "Alarme Customizado",
                    icon: "pi pi-fw pi-chart-bar",
                    to: "event-custom-alarm",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "Alerta Veículo sem Placa",
                    icon: "pi pi-fw pi-chart-bar",
                    to: "event-no-plate",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "Black List Comportamental",
                    icon: "pi pi-fw pi-chart-bar",
                    to: 'black-list-search',
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "Deslocamento Especial",
                    icon: "pi pi-fw pi-chart-bar",
                    to: "event-ab",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "Modo Comboio",
                    icon: "pi pi-fw pi-chart-bar",
                    to: "event-convoy",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "Alerta Veículo Clonado",
                    icon: "pi pi-fw pi-chart-bar",
                    to: "event-cloned-vehicle",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                }
            ],
        });

        response.push({
            label: "Escudo Rural",
            icon: "pi pi-shield",
            items: [
                {
                    label: "Modo Comboio Comportamental",
                    icon: "pi pi-fw pi-chart-bar",
                    to: "event-behavioral-convoy",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "Black List Comportamental",
                    icon: "pi pi-fw pi-chart-bar",
                    to: 'black-list-search',
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "Maquinário Pesado",
                    icon: "pi pi-fw pi-chart-bar",
                    to: 'machinery-vehicle',
                    command: (event) => {
                        history.push(event.item.to);
                    }
                }
            ]
        });

        response.push({
            label: "Preferências",
            icon: "pi pi-cog",
            items: [
                {
                    label: "Whatsapp",
                    icon: "pi pi-fw pi-database",
                    to: "whatsapp",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                }
            ]
        });

        if (sessionStorage.getItem("IsAdministrator") === "True") {
            response.push({
                label: "Administração",
                icon: "pi pi-fw pi-user",
                items: [
                    {
                        label: "Recursos",
                        icon: "pi pi-fw pi-database",
                        to: "features",
                        command: (event) => {
                            history.push(event.item.to);
                        }
                    },
                    {
                        label: "DSS Client",
                        icon: "pi pi-fw pi-database",
                        to: "dss-client",
                        command: (event) => {
                            history.push(event.item.to);
                        }
                    },
                    {
                        label: "Monitoramento",
                        icon: "pi pi-fw pi-chart-line",
                        to: "monitoring",
                        command: (event) => {
                            history.push(event.item.to);
                        }
                    }
                ]
            });

        }

        response.push({
            label: "DSM Monitor",
            icon: "pi pi-bell",
            items: [
                {
                    label: "MWS",
                    icon: "pi pi-fw pi-server",
                    to: "dsm-monitor-mws",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "MQR",
                    icon: "pi pi-fw pi-server",
                    to: "dsm-monitor-mqr",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "RED",
                    icon: "pi pi-fw pi-server",
                    to: "dsm-monitor-red",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "Cameras",
                    icon: "pi pi-fw pi-video",
                    to: "dsm-monitor-cameras",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
            ]
        })


        response.push({
            label: "Inteligência",
            icon: "pi pi-book",
            items: [
                {
                    label: "Histórico de veículos",
                    icon: "pi pi-fw pi-car",
                    to: "report-history",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },
                {
                    label: "Relatório de Comportamento",
                    icon: "pi pi-fw pi-chart-bar",
                    to: "behavior-report",
                    command: (event) => {
                        history.push(event.item.to);
                    }
                },

            ]
        });



        response.push({
            label: "Mapa",
            icon: "pi pi-map",
            command: (event) => {
                history.push('/map');
            }
        })

        // if (sessionStorage.getItem("IsAdministrator") === "True") {
        //     response.push({
        //         label: "Documentação",
        //         icon: "pi pi-book",
        //         command: (event) => {
        //             history.push('/documentation');
        //         }
        //     })
        // }
        return response;
    };

    const changeTheme = (e) => {
        props.handleChangeTheme();
    }


    const buildEnd = () => {
        return (
            <div className='center'>
                <i className={`pi mr-4 ${props.theme === `mdc-light-indigo` ? `pi-sun` : `pi-moon`}`}
                    style={{ cursor: `pointer` }}
                    onClick={e => changeTheme(e)}>
                </i>
                <span
                    onClick={e => { history.push('profile') }}
                    className='mr-2'
                    style={{ cursor: 'pointer' }}
                >
                    Olá, <strong>{sessionStorage.getItem("UserName")}</strong>
                </span>
                <Button label="Sair" icon="pi pi-power-off" onClick={(e) => history.push('/login')} />
            </div>
        );
    }

    const getImageSource = () => {
        var userTheme = localStorage.getItem("user-theme") || 'claro';

        return userTheme === 'claro' ? blackLogo : whiteLogo;
    }

    return (
        <div className='layout-topbar'>
            <Menubar
                model={getMenuItems()}
                start={
                    <img
                        src={getImageSource()}
                        className="layout-topbar-logo"
                        alt='top-bar-logo'
                    />
                }
                end={buildEnd()}>
            </Menubar>
        </div>
    );
}
