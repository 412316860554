import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { ToastServices } from "../../../../services/utils/toast-services";
import { UserServices } from "../../../../services/user-services";
import { user } from "../../../Login/types/types";
import "./../../styles/user.scss";

export interface DeleteUsersDialogProps {
    user: user;
    setLoading: (isLoading: boolean) => void;
    getUsers: () => void;
    setDeleteUsersDialog: (showUserDialog: boolean) => void;
    visible: boolean;
    setSelectedUsers: (selectedUsers: any) => void;
    selectedUsers: any;
}

export const DeleteUsersDialog = (props: DeleteUsersDialogProps) => {
    const userService = new UserServices();
    const _toastService = new ToastServices();
    const hideDeleteUsersDialog = () => {
        props.setDeleteUsersDialog(false);
    };

    const deleteSelectedUsers = () => {
        props.setLoading(true);
        props.selectedUsers.forEach((user: user) => {
            userService.deleteUser(user.id).then(
                () => {
                    props.getUsers();
                    _toastService.showSuccessMessage(`Usuário ${user.userName} Excluído com Sucesso.`);
                },
                (error: any) => {
                    props.getUsers();
                    _toastService.showErrorRequestMessage(error);
                    props.setLoading(false);
                }
            );
        });
        props.setSelectedUsers(null);

        props.setDeleteUsersDialog(false);
    };

    const deleteUsersDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsersDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUsers} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: "450px" }} header="Confirm" modal footer={deleteUsersDialogFooter} onHide={hideDeleteUsersDialog}>
            <ToastContainer />
            <Toast ref={toast} />
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                {props.user && <span className="ml-2">Você têm certeza que deseja excluir os usuários selecionados?</span>}
            </div>
        </Dialog>
    );
};
