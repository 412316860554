import axios from "axios";
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";

export class ClonedVehicleServices {
    baseControllerUrl: string = environment().baseUrl + environment().version + "/cloned-vehicle";
    _authServices = new AuthServices();

    getClonedVehicleEvents(cameraId: number, locationId: number) {
        return axios.get(this.baseControllerUrl + "/get-events", {
            headers: {
                Authorization: this._authServices.GetUserToken()
            },
            params: {
              locationId: locationId === 0 ? null : locationId,
              cameraId: cameraId === 0 ? null : cameraId
            }
        });
    }

    updateClonedVehicleTime(locationId: number, startTime: Date, stopTime: Date, whatsappGroupdId: number) {
        return axios.put(this.baseControllerUrl + '/update-cloned-vehicle-time/' + locationId, {}, {
            headers: {
                Authorization: this._authServices.GetUserToken()
            },
            params: {
                startTime: startTime.toLocaleString(),
                stopTime: stopTime.toLocaleString(),
                whatsappGroupId: whatsappGroupdId
            }
        });
    }

    getLocationClonedVehicleTime(locationId: number) {
        return axios.get(this.baseControllerUrl + '/' + locationId, {
            headers: {
                Authorization: this._authServices.GetUserToken()
            },
        });
    }
}
