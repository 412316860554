import classNames from 'classnames';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import React, { useState } from 'react';
import { DropdownInfo } from '../../../../models/types';
import { DownloadPdf } from '../components/download-pdf';
import { ToastServices } from '../../../../services/utils/toast-services';
import { Fieldset } from 'primereact/fieldset';

export interface DropdownFiltersProps {
    date: Date,
    today: Date,
    showPdfDialog: boolean,
    loadingCameras: boolean,
    loadingLocations: boolean,
    selectedLocation: number,
    cameras: DropdownInfo[],
    locations: DropdownInfo[],
    selectedCamera: number,
    selectedTimeSpan: number,
    setShowPdfDialog: (e: any) => void,
    onSelectedUF: (e: any) => void,
    onSelectedDate: (e: any) => void,
    onSelectedCamera: (e: any) => void,
    onSelectedTimeSpan: (e: any) => void,
    signalRConnected: boolean
}

export const DropdownFilters = (props: DropdownFiltersProps) => {
    const _toastServices = new ToastServices();
    const muteSoundState: string = localStorage.getItem('mute-sounds') ?? 'false';

    const [muteSound, setMuteSounds] = useState<boolean>(muteSoundState === 'false' ? false : true);

    const isOlderDateSelected = (selectedDate: Date): boolean => {
        let dateDay: number = selectedDate.getDate();
        let todayDay: number = props.today.getDate();

        let dateMonth: number = selectedDate.getMonth();
        let todayMonth: number = props.today.getMonth();

        let dateYear: number = selectedDate.getFullYear();
        let todayYear: number = props.today.getFullYear();

        return dateDay < todayDay || dateMonth < todayMonth || dateYear < todayYear;
    };

    const onMuteSoundClick = () => {
        localStorage.setItem('mute-sounds', muteSoundState === 'false' ? 'true' : 'false');

        var _newValue: boolean = muteSoundState === 'false' ? true : false;
        var message: string = _newValue === true ? 'ALERTA SONOROS DESATIVADOS' : 'ALERTA SONOROS ATIVADOS';

        _toastServices.showSuccessMessage(message);
        setMuteSounds(_newValue);
    }
    const muteSoundIcon = () => {
        var iconName: string = 'pi pi-volume-up';
        var title: string = 'DESATIVAR ALARMES SONOROS';
        var severity: any = 'success';
        var value: string = 'Alertas Sonoros Ligados'

        if (muteSound === true) {
            iconName = 'pi pi-volume-off';
            title = 'ATIVAR ALARMES SONOROS';
            severity = 'danger';
            value = 'Alertas Sonoros Desligados';
        }

        return <Tag severity={severity} title={title} value={value} style={{ cursor: 'pointer' }} onClick={(e) => onMuteSoundClick()} icon={iconName} />;
    }
    const signalRStatus = () => {
        return props.signalRConnected ? <Tag severity="success" value="Signal-R Conectado" icon="pi pi-check" /> : <Tag severity="danger" value="Signal-R Desconectado" icon="pi pi-times" />;
    }
    const headerFilterFieldset = <>
        <span className="titulo-categoria">
            <i className='pi pi-filter mr-2'></i>
            Filtros
        </span>
    </>
    const headerPreferencesFieldset = <>
        <span className="titulo-categoria">
            <i className='pi pi-cog mr-2'></i>
            Preferências/Ações
        </span>
    </>
    return (
        <React.Fragment>
            <div className="col-8">
                <Fieldset legend={headerFilterFieldset}>
                    <div className="grid">
                        <div className="col-4 lg:col-12 xl:col-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-map-marker"></i>
                                </span>
                                <Calendar
                                    value={props.date}
                                    maxDate={props.today}
                                    onChange={(e: any) => props.onSelectedDate(e)}
                                    dateFormat="dd/mm/yy"
                                    className={classNames({ "p-invalid": isOlderDateSelected(props.date) })}
                                />
                            </div>
                        </div>
                        <div className="col-4 lg:col-12 xl:col-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-map-marker"></i>
                                </span>
                                <Dropdown
                                    autoFocus
                                    value={props.selectedLocation}
                                    disabled={props.loadingLocations}
                                    onChange={props.onSelectedUF}
                                    placeholder="Selecione uma cidade..."
                                    optionLabel="description"
                                    optionValue="id"
                                    options={props.locations}
                                />
                            </div>
                        </div>
                        <div className="col-4 lg:col-12 xl:col-4">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-camera"></i>
                                </span>
                                <Dropdown
                                    autoFocus
                                    value={props.selectedCamera}
                                    placeholder="Selecione uma câmera..."
                                    optionLabel="description"
                                    optionValue="id"
                                    disabled={props.loadingCameras || props.selectedLocation === 0}
                                    onChange={(e: any) => props.onSelectedCamera(e)}
                                    options={props.cameras}
                                />
                            </div>
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="col-4">
                <Fieldset legend={headerPreferencesFieldset}>
                    <div className='flex align-items-center justify-content-center'>
                        <div className='mr-4'>
                            {signalRStatus()}
                        </div>
                        <div className='mr-4'>
                            {muteSoundIcon()}
                        </div>
                        <div className='mr-4'>
                            <DownloadPdf
                                cameras={props.cameras}
                                selectedTimeSpan={props.selectedTimeSpan}
                                onSelectedTimeSpan={props.onSelectedTimeSpan}
                                loadingCameras={props.loadingCameras}
                                loadingLocations={props.loadingLocations}
                                locations={props.locations}
                                onSelectedCamera={props.onSelectedCamera}
                                onSelectedDate={props.onSelectedDate}
                                onSelectedUF={props.onSelectedUF}
                                selectedCamera={props.selectedCamera}
                                selectedLocation={props.selectedLocation}
                                showPdfDialog={props.showPdfDialog}
                                setShowPdfDialog={props.setShowPdfDialog}
                            />
                        </div>
                    </div>
                </Fieldset>
            </div>
        </React.Fragment>
    );
}
