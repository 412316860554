import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "../../styles/dsmApplication.scss";
import { Toolbar } from "primereact/toolbar";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DropdownInfo } from "../../../../models/types";
import { location } from "../../../DSM-Monitor/types/types";
import { ToastServices } from "../../../../services/utils/toast-services";
import { DSMApplicationServices } from "../../../../services/dsm-application-services";
import { onCheckedChange, onInputChange } from "../../../../services/utils/input-services";
import { CreateDSMApplicationLocationDialog } from "./create-dsm-application-location-dialog";
import { SelectDSMApplicationLocationDialog } from "./select-dsm-application-location-dialog";
import { DSMApplicationLocationsDataTable } from "../data-tables/dsm-application-locations-data-table";
import { dsmApplication, dsmApplicationLocation, emptyDSMApplicationLocation } from "../../types/types";

export interface CreateDSMApplicationDialogProps {
    dsmApplication: dsmApplication;
    setDSMApplication: (dsmApplication: dsmApplication) => void;
    setDSMApplicationDialog: (showDSMApplicationDialog: boolean) => void;
    isEditingDSMApplication: boolean;
    visible: boolean;
    submitted: boolean;
    setSubmitted: (value: boolean) => void;
    onSubmit: () => void;
    updateHash: () => void;
    hideDialog: () => void;
    dialogHeader: string;
}

export const CreateDSMApplicationDialog = (props: CreateDSMApplicationDialogProps) => {
    const dsmApplicationService = new DSMApplicationServices();
    const _toastService = new ToastServices();
    const _dsmApplicationServices = new DSMApplicationServices();

    const [showNewLocationDialog, setShowNewLocationDialog] = useState<boolean>(false);
    const [showNewSelectLocationDialog, setShowNewSelectLocationDialog] = useState<boolean>(false);
    const [selectedState, setSelectedState] = useState<string>(null);
    // const [selectedLocation, setSelectedLocation] = useState<dsmApplicationLocation>(emptyDSMApplicationLocation);
    const [selectedLocations, setSelectedLocations] = useState<DropdownInfo[]>([]);

    const [dataLocation, setDataLocation] = useState<string>(null);

    const [visibleConfirm, setVisibleConfirm] = useState(false);

    // Abre o diálogo de confirmação
    const openConfirmDialog = () => {
        setVisibleConfirm(true);
    };

    // Fecha o diálogo de confirmação
    const closeConfirmDialog = () => {
        setVisibleConfirm(false);
    };

    // Confirma a atualização do hash e chama updateHash()
    const confirmUpdateHash = () => {
        props.updateHash(); // Chama a função de atualização
        setVisibleConfirm(false); // Fecha o diálogo
        props.hideDialog()
    };

    useEffect(() => {
        const fetchDSMApplication = async () => {
            if (props.isEditingDSMApplication && props.dsmApplication?.dsmApplicationId) {
                try {
                    updateStateByDSMApplication();
                    getCityById(props.dsmApplication.locationId);
                    console.log("Buscando DSMApplication com ID:", props.dsmApplication.dsmApplicationId);
                    const response = await _dsmApplicationServices.getDSMApplicationbyId(props.dsmApplication.dsmApplicationId);
                    props.setDSMApplication(response.data.value);

                    // Atualiza a lista de localizações
                    if (response.data.dsmApplicationLocations) {
                        setSelectedLocations(
                            response.data.dsmApplicationLocations.map((loc: dsmApplicationLocation) => ({
                                id: loc.locationId,
                                description: loc.locationName
                            }))
                        );
                    }
                } catch (error) {
                    console.error("Erro ao buscar DSMApplication:", error);
                    _toastService.showErrorRequestMessage(error);
                }
            } else {
                setDataLocation(null);
                props.dsmApplication.dsmApplicationLocations = []
            }
        };

        fetchDSMApplication();
    }, [props.isEditingDSMApplication, props.dsmApplication?.dsmApplicationId]);


    const updateCitiesByState = (stateName: string) => {
        setSelectedState(stateName)
        dsmApplicationService.getCitiesByState(stateName).then(
            (response) => {
                setSelectedLocations(response.data)
            },
            (error: any) => {
                _toastService.showErrorRequestMessage(error)
            });
    };

    const getCityById = (id: number) => {
        dsmApplicationService.getCityById(id).then(
            (response) => {
                setSelectedLocations(response.data.locationName)
                setSelectedState(response.data.stateProvince)
                setDataLocation(response.data.locationName + " - " + response.data.stateProvince)
            },
            (error: any) => {
                _toastService.showErrorRequestMessage(error)
            });
    };

    const updateStateByDSMApplication = () => {
        dsmApplicationService.getStateByApplication(props.dsmApplication.dsmApplicationId).then(
            (response) => {
                if (response.data != null && response.data != "") {
                    setSelectedState(response.data)
                    updateCitiesByState(response.data)
                }
            },
            (error: any) => _toastService.showErrorRequestMessage(error));
    };

    const userDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={(e) => props.onSubmit()} />
        </>
    )

    const leftToolbarSelectTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-">
                    <Button label="Nova Localidade" icon="pi pi-plus" className="p-button-success" onClick={(e) => openNewSelectLocationDialog()} />
                </div>
            </React.Fragment>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-">
                    <Button label="Nova Localidade" icon="pi pi-plus" className="p-button-success" onClick={(e) => openNewLocationDialog()} />
                </div>
            </React.Fragment>
        );
    }

    const closeNewSelectLocationDialog = () => {
        setShowNewSelectLocationDialog(false);
    }

    const openNewSelectLocationDialog = () => {
        setShowNewSelectLocationDialog(true);
    }

    const closeNewLocationDialog = () => {
        setShowNewLocationDialog(false);
    }

    const openNewLocationDialog = () => {
        setShowNewLocationDialog(true);
    }

    const addLocation = (location: location) => {

        let _dsm_application = { ...props.dsmApplication } as any;
        _dsm_application.locationId = location.locationId;

        props.setDSMApplication(_dsm_application);
        // setSelectedLocation(location)
        closeNewLocationDialog();

    }


    const deleteLocation = (rowData: any) => {
        let locations = props.dsmApplication.dsmApplicationLocations.filter((x) => x.locationId !== rowData.locationId);

        let _dsm_application = { ...props.dsmApplication } as any;
        _dsm_application.dsmApplicationLocations = locations;

        props.setDSMApplication(_dsm_application);
    }

    const typeOptions = [
        { label: 'MWS', value: 'MWS' },
        { label: 'MQR', value: 'MQR' },
        { label: 'RED', value: 'RED' }
    ];

    const modoOptions = [
        { label: 'Master', value: 'master' },
        { label: 'Slave', value: 'slave' }
    ];

    return (
        <div className="p-grid crud-demo">
            <div className="card">
                <Dialog visible={props.visible} style={{ width: "450px" }} header={props.dialogHeader} modal className="p-fluid" footer={userDialogFooter} onHide={props.hideDialog}>
                    <span className="titulo-categoria">IDENTIFICAÇÃO:</span>
                    <div className="categoria">
                        <div className="grid">
                            <div className="p-field col-6">
                                <span>Nome*</span>
                                <InputText
                                    id="name"
                                    type="text"
                                    value={props.dsmApplication.dsmApplicationName}
                                    onChange={(e) => onInputChange(e, "dsmApplicationName", props.dsmApplication, props.setDSMApplication)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.dsmApplication.dsmApplicationName })}
                                />
                                {props.submitted && !props.dsmApplication.dsmApplicationName && <small className="p-invalid">Insira um Nome.</small>}
                            </div>
                            <div className="p-field col-6">
                                <span>Tipo*</span>
                                <Dropdown
                                    id="type"
                                    value={props.dsmApplication.type}
                                    options={typeOptions}
                                    onChange={(e) => onInputChange(e, "type", props.dsmApplication, props.setDSMApplication)}
                                    placeholder="Selecione um tipo"
                                    className={classNames({ "p-invalid": props.submitted && !props.dsmApplication.type })}
                                />
                                {props.submitted && !props.dsmApplication.type && <small className="p-invalid">Escolha um tipo.</small>}
                            </div>

                            <div className="p-field col-6">
                                <span>Modo*</span>
                                <Dropdown
                                    id="type"
                                    value={props.dsmApplication.modo}
                                    options={modoOptions}
                                    onChange={(e) => onInputChange(e, "modo", props.dsmApplication, props.setDSMApplication)}
                                    placeholder="Selecione um modo"
                                    className={classNames({ "p-invalid": props.submitted && !props.dsmApplication.modo })}
                                />
                                {props.submitted && !props.dsmApplication.modo && <small className="p-invalid">Escolha um modo.</small>}
                            </div>

                            <div className="p-field col-6">
                                <span>Localidade*</span>
                                <SelectDSMApplicationLocationDialog
                                    visible={showNewSelectLocationDialog}
                                    closeDialog={closeNewSelectLocationDialog}
                                    selectedState={selectedState}
                                    setSelectedState={setSelectedState}
                                    setLocation={(location) => { addLocation(location); }}
                                    setSelectedLocations={(locations) => { setSelectedLocations(locations); }}
                                    isEditing={props.isEditingDSMApplication}
                                />
                                <Toolbar className="p-mb-4" left={leftToolbarSelectTemplate} />
                                {dataLocation && (
                                    <span>{dataLocation}</span>
                                )}
                                <div />
                            </div>
                            {props.isEditingDSMApplication &&
                                (<div className="p-field col-12" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <span>Hash Key</span>
                                    <InputText
                                        id="hash"
                                        type="text"
                                        value={props.dsmApplication.mD5Hash}
                                        disabled={true}
                                        className="custom-hash-input"
                                    />
                                    <Button
                                        icon="pi pi-refresh"
                                        className="p-button-rounded p-button-primary"
                                        onClick={openConfirmDialog}
                                        tooltip="Atualizar Hash"
                                    />
                                </div>)}
                        </div>
                    </div>
                    <span className="titulo-categoria">REDE:</span>
                    <div className="categoria">
                        <div className="grid">
                            <div className="p-field-checkbox col-4">
                                <Checkbox
                                    className="mr-2"
                                    inputId="usingDDNS"
                                    name="option"
                                    checked={props.dsmApplication.usingDDNS}
                                    onChange={(e) => onCheckedChange(e, "usingDDNS", props.dsmApplication, props.setDSMApplication)}
                                />
                                <span>DDNS</span>
                            </div>
                            <div className="p-field col-8">
                                <span>Endereço IP*</span>
                                <InputText
                                    id="ipAddress"
                                    type="text"
                                    value={props.dsmApplication.ipAddress}
                                    onChange={(e) => onInputChange(e, "ipAddress", props.dsmApplication, props.setDSMApplication)}
                                    required
                                    className={classNames({ "p-invalid": props.submitted && !props.dsmApplication.ipAddress })}
                                />
                                {props.submitted && !props.dsmApplication.dsmApplicationName && <small className="p-invalid">Insira um Nome.</small>}
                            </div>
                        </div>
                    </div>
                    <span className="titulo-categoria">LOCAIS DE ATENDIMENTO:</span>
                    <div className={'categoria'}>
                        <CreateDSMApplicationLocationDialog
                            visible={showNewLocationDialog}
                            closeDialog={closeNewLocationDialog}
                            setSelectedLocations={(locations) => { setSelectedLocations(locations); }}
                            selectedLocations={selectedLocations}
                            dsmApplicationLocations={props.dsmApplication.dsmApplicationLocations}
                            isEditing={props.isEditingDSMApplication}
                        />
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} />
                        <DSMApplicationLocationsDataTable
                            dsmApplicationLocations={props.dsmApplication.dsmApplicationLocations}
                            deleteLocation={deleteLocation}
                        />
                    </div>
                    {props.submitted && props.dsmApplication.dsmApplicationLocations.length <= 0 && <small className="p-invalid">Selecione pelo menos uma localidade.</small>}
                </Dialog>

                {/* Diálogo de Confirmação */}
                <Dialog
                    visible={visibleConfirm}
                    style={{ width: "400px" }}
                    header="Confirmação"
                    modal
                    footer={
                        <>
                            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={closeConfirmDialog} />
                            <Button label="Sim" icon="pi pi-check" className="p-button-danger" onClick={confirmUpdateHash} />
                        </>
                    }
                    onHide={closeConfirmDialog}
                >
                    <p>Tem certeza de que deseja atualizar o Hash Key?</p>
                    <p>Após atualizar; será necessário fazer a atualização do hash nas configurações locais da aplicação, portanto essa modificação inutiliza o Hash anterior provocando a interrupção da aplicação local.</p>
                </Dialog>

            </div>
        </div>
    )
};
