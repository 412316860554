import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { ToastServices } from "../../../services/utils/toast-services";
import { CustomAlarmService } from "../../../services/custom-alarm-service";
import { customAlarmInterface } from "../types/types";

export interface DeleteCustomAlarmsDialogProps {
    customAlarm: customAlarmInterface;
    setLoading: (isLoading: boolean) => void;
    getCustomAlarms: () => void;
    setDeleteCustomAlarmsDialog: (showCustomAlarmsDialog: boolean) => void;
    visible: boolean;
    setSelectedCustomAlarms: (selectedCustomAlarms: any) => void;
    selectedCustomAlarms: any;
}

export const DeleteCustomAlarmsDialog = (props: DeleteCustomAlarmsDialogProps) => {
    const customAlarmService = new CustomAlarmService();
    const _toastService = new ToastServices();

    const hideDeleteCustomAlarmsDialog = () => {
        props.setDeleteCustomAlarmsDialog(false);
    };

    const deleteSelectedCustomAlarms = () => {
        props.setLoading(true);
        props.selectedCustomAlarms.forEach((customAlarm: customAlarmInterface) => {
            customAlarmService.deleteCustomAlarm(customAlarm.id).then(
                () => {
                    props.getCustomAlarms();
                    _toastService.showSuccessMessage(`Alarme Customizado Excluído com Sucesso.`);
                },
                (error: any) => {
                    props.getCustomAlarms();
                    _toastService.showErrorRequestMessage(error);
                    props.setLoading(false);
                }
            );
        });
        props.setSelectedCustomAlarms(null);

        props.setDeleteCustomAlarmsDialog(false);
    };

    const deleteCustomAlarmsDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCustomAlarmsDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCustomAlarms} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: "450px" }} header="Confirm" modal footer={deleteCustomAlarmsDialogFooter} onHide={hideDeleteCustomAlarmsDialog}>
            <ToastContainer />
            <Toast ref={toast} />
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                {props.customAlarm && <span className="ml-2">Você têm certeza que deseja excluir os alarmes customizados selecionados?</span>}
            </div>
        </Dialog>
    );
};
