
export const emptyLocation = {
} as location;

export const emptyDSMApplication: dsmApplication = {
    dsmApplicationId: 0,
    locationId: 0,
    dsmApplicationName: '',
    type: '',
    modo: '',
    mD5Hash: '',
    ipAddress: '',
    usingDDNS: false,
    dsmApplicationLocations: []
};

export interface location {
    locationId: number;
    locationName: string;
    stateProvince: string;
}

export const emptyDSMApplicationLocation = {
} as dsmApplicationLocation

export interface dsmApplicationLocation {
    locationId: number;
    locationName: string;
}


export interface dsmApplication {
    dsmApplicationId: number;
    locationId: number;
    dsmApplicationName: string;
    type: string;
    modo: string;
    mD5Hash: string;
    ipAddress: string;
    usingDDNS: boolean;
    dsmApplicationLocations: dsmApplicationLocation[];
}


