import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { ToastServices } from "../../../../services/utils/toast-services";
import { UserServices } from "../../../../services/user-services";
import { user } from "../../../Login/types/types";
import "./../../styles/user.scss";

export interface DeleteUserDialogProps {
    user: user;
    setLoading: (isLoading: boolean) => void;
    setUser: (user: user) => void;
    getUsers: () => void;
    emptyUser: user;
    setDeleteUserDialog: (showUserDialog: boolean) => void;
    visible: boolean;
}

export const DeleteUserDialog = (props: DeleteUserDialogProps) => {
    const userService = new UserServices();
    const _toastService = new ToastServices();
    const hideDeleteUserDialog = () => {
        props.setDeleteUserDialog(false);
    };

    const deleteUser = () => {
        props.setLoading(true);
        userService.deleteUser(props.user.id).then(
            () => {
                props.setDeleteUserDialog(false);
                props.setUser(props.emptyUser);
                props.setLoading(false);
                _toastService.showSuccessMessage(`Usuário ${props.user.userName} Excluído com Sucesso.`);
                props.getUsers();
            },
            (error) => {
                props.getUsers();
                _toastService.showErrorRequestMessage(error);
                props.setLoading(false);
            }
        );
    };

    const deleteUserDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteUser} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: "450px" }} header="Confirmação" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
            <ToastContainer />
            <Toast ref={toast} />
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3 " style={{ fontSize: "2rem" }} />
                {props.user && (
                    <span className="ml-2">
                        Você têm certeza que deseja excluir o usuário <b>{props.user.userName}</b>?
                    </span>
                )}
            </div>
        </Dialog>
    );
};
