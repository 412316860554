import { BehavioralConvoyAlertResponse, blackListResponse, ClonedVehicleAlertResponse, ConvoyAlertResponse, CustomAlarmAlertResponse, DropdownInfo, DssEventSubscriptionResponse, NoPlateAlertResponse, PointABAlertResponse } from "../../../models/types";
import { VehicleInformationsResponse } from "../../Event-behavioral-convoy/types/types";

export interface locationIdentity {
    locationId: number;
    locationName: string;
    stateProvince: string;
}

export const blankRouteResponse: routeResponse = {
    cameraName: ``,
    cameraId: 0,
    licensePlate: ``,
    latitude: ``,
    longitude: ``,
    detectionTime: ``,
    location: null,
}
export interface routeResponse {
    cameraId: number;
    cameraName: string;
    licensePlate: string;
    latitude: string;
    longitude: string;
    detectionTime: string;
    location: locationIdentity;
}

export interface RoutingProps {
    waypoints: [number, number][]; // Array de pontos de rota [latitude, longitude]
}
export interface listRouteResponse {
    licensePlate: string;
    vehicleRouteResponses: routeResponse[];
}
export interface RouteListDataTableProps {
    setIsRoutePopupOpen: (val: boolean) => void;
    initialDate: Date | null;
    finalDate: Date | null;
    selectedPlate: string;
    listRoutes: listRouteResponse[];
    loading: boolean;
    globalFilter: string;
    setGlobalFilter: (val: any) => void,
    selectRoute: (rowData: routeResponse[]) => void;

}
export interface VehicleRouteProps {
    setIsRoutePopupOpen: (val: boolean) => void;
    locations: DropdownInfo[];
    loading: boolean;
    onSelectRoute: (val: routeResponse[]) => void,
    onSelectedRouteLocation: (e: any) => void,
    selectedRouteLocation: number;
}
export interface SideBarMenuProps {
    isSideBarOpen: boolean;
    // onLegendClick: () => void;
    onSettingsClick: () => void;
    onFilterClick: () => void;
    onRouteClick: () => void;
}
export interface CameraMarkerProps {
    clickedCamera: boolean;
    cameraEvents: { [key: number]: plateEventResponse | null }; // Mapeamento de cameraId para evento
    onClickedCamera: (val: boolean) => void;
    camera: Camera;
    activePopupCameraId: number | null;
    readPlate: plateEventResponse;
    vehicleCount: any;  // Contagem de veículos passada como prop
}
export interface CameraCardProps {
    camera: Camera;
    readPlate: plateEventResponse | null;
    vehicleCount: {
        quantityCar: number;
        quantityMotorBike: number;
        quantityTruck: number;
        quantityMachinery: number;
        quantityBus: number;
        quantityPickup: number;
        quantityOther: number;
    };
}
export interface DynamicMapCenterProps {
    latitude: number;
    longitude: number;
    zoom: number;
}
export interface HeatmapLayerProps {
    heatmapPoints: [number, number, number][];  // [latitude, longitude, intensidade]
    radius: number;
    blur: number;
    maxZoom: number;
}
export interface IntegrationCountProps {
    isIntegrationLoading: boolean,
    isOutDated: boolean,
    integrationCardResponseHelios: integrationCardResponse;
    integrationCardResponseCortex: integrationCardResponse;
    integrationCardResponseAlerta: integrationCardResponse;
    renderCardValues: (value: string) => JSX.Element,
}
export interface RoutingMapProps {
    start: [number, number]; // Coordenadas de início
    end: [number, number];   // Coordenadas de fim
}

export interface LegendProps {
    intensityFactor: number;
    radius: number;
    blur: number;
    maxZoom: number;
    maxVehicles: number; // Valor máximo vindo das câmeras, limitado a 1000.
}

export interface SlideProps {
    intensityFactor: number;
    setIntensityFactor: (val: number) => void;
    radius: number;
    setRadius: (val: number) => void;
    blur: number;
    setBlur: (val: number) => void;
    maxZoom: number;
    setMaxZoom: (val: number) => void;
}

export interface VisibleCamerasProps {
    cameraEvents: { [key: number]: plateEventResponse | null }; // Mapeamento de cameraId para evento
    cameras: Camera[];  // Lista de câmeras
    readPlate: plateEventResponse;
    clickedCamera: boolean;
    onClickedCamera: (val: any) => void;  // Função chamada ao selecionar uma câmera
    activePopupCameraId: number | null;  // ID da câmera que deve mostrar o popup
    vehicleCountsByCamera: { [cameraId: number]: any }; // Contagens de veículos por câmera
}

export interface SignalRLiveMapProps {
    isRoutePopupOpen: boolean;
    // isLegendPopupOpen: boolean;
    isSettingsPopupOpen: boolean;
    isFilterPopupOpen: boolean;
    loading: boolean;
    cameras: Camera[];
    selectedLocation: number;
    listLocations: location[];
    selectedCamera: number;
    selecteDate: Date;
    intensityFactor: number;
    maxZoom: number;
    radius: number;
    blur: number;
    setSignalRConnected: (val: boolean) => void;
    onSelectedCamera: (val: any) => void;
    selectedVehicleTypes: (keyof cardResponse)[]; // Aqui indicamos que as opções são chaves de cardResponse
    routeList: routeResponse[]; // Nova propriedade para receber a lista de rotas
}

export interface cardResponse {
    cameraId: number,
    totalFailure: number,
    totalIntegrations: number,
    totalRead: number,
    quantityCar: number,
    quantityMotorBike: number,
    quantityTruck: number,
    quantityMachinery: number,
    quantityBus: number,
    quantityPickup: number,
    quantityOther: number,
}

export interface Camera {
    id: number;
    dscCamera: string;
    nomUF: string;
    nomLocalidade: string;
    dscUsuario: string;
    dscSenha: string;
    dscEndereco: string;
    numLatitude: number;
    numLongitude: number;
    dscUrl: string;
    numPorta: number;
    numImageMode: number;
    idLocalidade: number;
    velocidadeMaxima: number;
    nomCameraModelo: string;
    idCameraModelo: number;
    indAtivo: boolean;
    indSignalR: boolean;
    // vehicleCount?: number;
    // integrations: cameraIntegration[];
}

export interface integrationCardResponse {
    totalRead: number,
    locationId: number,
    cameraId: number,
    totalFailure: number,
    totalSuccess: number,
    activeCameras: number,
    locationCount: number,
    inactiveCameras: number,
    isSuccess: boolean
}

export interface plateEventResponse {
    camera: string,
    captureTime: string,
    plate: string,
    integrations: integrationMessageResponse[],
    isSucess: boolean,
    cameraId: number,
    locationId: number,
    vehicleType: string,
    vehicleBrand: string,
    vehicleColor: string,
    vehicleSpeed: number,
    cameraMaxSpeed: number,
    vehicleImageBytes: string
}

export const blankPlateEventResponse = {
    camera: '',
    captureTime: '',
    plate: '',
    integrations: [] as integrationMessageResponse[],
    isSucess: false,
    cameraId: 0,
    locationId: 0,
    vehicleType: '',
    vehicleBrand: '',
    vehicleColor: '',
    vehicleSpeed: 0,
    cameraMaxSpeed: 0,
    vehicleImageBytes: ''
}

export interface integrationMessageResponse {
    name: string,
    success: boolean
}

export interface integrationCardResponse {
    totalRead: number,
    locationId: number,
    cameraId: number,
    totalFailure: number,
    totalSuccess: number,
    activeCameras: number,
    locationCount: number,
    inactiveCameras: number,
    isSuccess: boolean
}

export const blankIntegrationCard = {
    totalRead: 0,
    totalFailure: 0,
    totalSuccess: 0,
    activeCameras: 0,
    inactiveCameras: 0,
    locationCount: 0,
    locationId: 0
} as integrationCardResponse;

export interface location {
    id: number,
    latitude: string,
    longitude: string
}

export const emptyLocation: location = {
    id: 0,
    latitude: '',
    longitude: ''
}

export interface DropdownFiltersProps {
    date: Date,
    today: Date,
    showPdfDialog: boolean,
    loading: boolean,
    loadingLocations: boolean,
    selectedLocation: number,
    cameras: DropdownInfo[],
    locations: DropdownInfo[],
    selectedCamera: number,
    selectedTimeSpan: number,
    selectedVehicleTypes: string[],  // Aqui definimos os tipos de veículos selecionados
    setShowPdfDialog: (e: any) => void,
    onSelectedLocation: (e: any) => void,
    onSelectedDate: (e: any) => void,
    onSelectedCamera: (e: any) => void,
    onSelectedTimeSpan: (e: any) => void,
    onSelectedVehicleTypes: (e: any) => void,  // Adicionamos essa função
    signalRConnected: boolean
}

export const blankBlackListAlert: blackListResponse = {
    cameraName: ``,
    dateTime: ``,
    imageUrl: ``,
    plate: ``,
    description: ``,
    reason: ``,
    address: ``,
    locationId: 0
}

export const blankConvoyAlert: ConvoyAlertResponse = {
    firstVehicleImageUrl: '',
    secondVehicleImageUrl: '',
    cameraAddress: '',
    cameraName: '',
    firstVehiclePlate: '',
    secondVehiclePlate: '',
    firstEventDateTime: '',
    secondEventDateTime: '',
    firstVehicleBrand: 0,
    firstVehicleColor: 0,
    firstVehicleType: 0,
    secondVehicleBrand: 0,
    secondVehicleColor: 0,
    secondVehicleType: 0,
    locationId: 0,
    whatsappGroupId: null
}

export const blankClonedVehicleAlert: ClonedVehicleAlertResponse = {
    firstVehicleImageUrl: '',
    secondVehicleImageUrl: '',
    cameraAddress: '',
    cameraName: '',
    firstVehiclePlate: '',
    secondVehiclePlate: '',
    firstEventDateTime: '',
    secondEventDateTime: '',
    firstVehicleBrand: 0,
    firstVehicleColor: 0,
    firstVehicleType: 0,
    secondVehicleBrand: 0,
    secondVehicleColor: 0,
    secondVehicleType: 0,
    locationId: 0,
    whatsappGroupId: null
}

export const blankPointABAlert: PointABAlertResponse = {
    imageUrlPointA: '',
    imageUrlPointB: '',
    cameraAddressPointA: '',
    cameraAddressPointB: '',
    cameraNamePointA: '',
    cameraNamePointB: '',
    plate: '',
    dateTimePointA: '',
    dateTimePointB: '',
    locationId: 0
}

export const blankCustomAlarmAlert: CustomAlarmAlertResponse = {
    plate: '',
    dateTime: '',
    cameraAddress: '',
    cameraName: '',
    imageUrl: '',
    vehicleTypeId: 0,
    vehicleColorId: 0,
    vehicleBrandId: 0,
    locationId: 0,
    numberOfPassengers: 0
}

export const blankNoPlateAlert: NoPlateAlertResponse = {
    dateTime: '',
    cameraAddress: '',
    cameraName: '',
    imageUrl: '',
    locationId: 0,
}

export const blankVehicleInformations: VehicleInformationsResponse = {
    date: '',
    imageUrl: '',
    type: '',
    vehiclePlate: ''
}

export const blankBehavioralConvoyAlert: BehavioralConvoyAlertResponse = {
    cameraAddress: '',
    cameraName: '',
    locationId: 0,
    firstVehicleType: blankVehicleInformations,
    secondVehicleType: blankVehicleInformations
}

export const blankDssEventSubscriptionAlert: DssEventSubscriptionResponse = {
    alarmDescription: '',
    azureImageUrl: '',
    vehiclePlate: '',
    cameraName: '',
    alarmTime: '',
    alarmType: '',
    locationId: 0,
}

