import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { ToastServices } from "../../../services/utils/toast-services";
import { CustomAlarmService } from "../../../services/custom-alarm-service";
import { customAlarmInterface } from "../types/types";

export interface DeleteCustomAlarmDialogProps {
    customAlarm: customAlarmInterface;
    setLoading: (isLoading: boolean) => void;
    setCustomAlarm: (customAlarm: customAlarmInterface) => void;
    getCustomAlarms: () => void;
    emptyCustomAlarm: customAlarmInterface;
    setDeleteCustomAlarmDialog: (showCustomAlarmDialog: boolean) => void;
    visible: boolean;
}

export const DeleteCustomAlarmDialog = (props: DeleteCustomAlarmDialogProps) => {
    const customAlarmService = new CustomAlarmService();
    const _toastService = new ToastServices();

    const hideDeleteCustomAlarmDialog = () => {
        props.setDeleteCustomAlarmDialog(false);
    };

    const deleteCustomAlarm = () => {
        props.setLoading(true);
        customAlarmService.deleteCustomAlarm(props.customAlarm.id).then(
            () => {
                props.setDeleteCustomAlarmDialog(false);
                props.setCustomAlarm(props.customAlarm);
                props.setLoading(false);
                _toastService.showSuccessMessage(`Alarme customizado excluído com sucesso.`);
                props.getCustomAlarms();
            },
            (error: any) => {
                props.getCustomAlarms();
                _toastService.showErrorRequestMessage(error);
                props.setLoading(false);
            }
        );
    };

    const deleteCustomAlarmDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCustomAlarmDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteCustomAlarm} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: "450px" }} header="Confirmação" modal footer={deleteCustomAlarmDialogFooter} onHide={hideDeleteCustomAlarmDialog}>
            <ToastContainer />
            <Toast ref={toast} />
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3 " style={{ fontSize: "2rem" }} />
                {props.customAlarm && (
                    <span className="ml-2">
                        Você têm certeza que deseja excluir este alarme customizado?
                    </span>
                )}
            </div>
        </Dialog>
    );
};
