import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { DropdownInfo } from "../../../../models/types";
import { ToastServices } from "../../../../services/utils/toast-services";
import { getUFList } from "../../../../services/camera-services";
import { DSMApplicationServices } from "../../../../services/dsm-application-services";
import { emptyLocation, location } from "../../types/types";

export interface SelectDSMApplicationLocationDialogProps {
    visible: boolean;
    closeDialog: () => void;
    setLocation: (location: location) => void;
    setSelectedLocations: (locations: DropdownInfo[]) => void;
    isEditing: boolean;
    selectedState: string;
    setSelectedState: (state: string) => void;
}

export const SelectDSMApplicationLocationDialog = (props: SelectDSMApplicationLocationDialogProps) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<location>(emptyLocation);
    const [loadingCities, setLoadingCities] = useState<boolean>(false); // Estado para controle de carregamento

    const dsmApplicationService = new DSMApplicationServices();
    const _toastService = new ToastServices();

    const addNewLocation = () => {
        setSubmitted(true);

        if (selectedLocation) {
            props.setLocation(selectedLocation);
            props.closeDialog();
            setSubmitted(false);
            props.setSelectedState(null);
            setSelectedLocation(emptyLocation);
            setLocations([]);
        }
    };

    const newLocationDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={props.closeDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={(e) => addNewLocation()} />
        </>
    );

    useEffect(() => {
        if (props.selectedState != null) {
            getCitiesByState(props.selectedState);
        }
    }, [props.selectedState]);

    const updateCitiesByState = (e: any) => {
        const stateName = e.target.value;
        props.setSelectedState(stateName);
        getCitiesByState(stateName);
    };

    const getCitiesByState = (stateName: string) => {
        setLoadingCities(true); // Inicia o estado de carregamento

        dsmApplicationService.getCitiesByState(stateName).then(
            (response) => {
                setLocations(response.data);
                props.setSelectedLocations(response.data);
                setLoadingCities(false); // Finaliza o estado de carregamento após obter as cidades
            },
            (error: any) => {
                _toastService.showErrorRequestMessage(error);
                setLoadingCities(false); // Finaliza o estado de carregamento em caso de erro
            }
        );
    };

    const onDropdownChange = (e: any) => {
        const locationDropdownObject = locations.find((x) => x.id === e.target.value);
        const locationObject = {
            locationId: locationDropdownObject.id,
            locationName: locationDropdownObject.description,
        } as location;

        setSelectedLocation(locationObject);
    };

    return (
        <>
            <Dialog
                visible={props.visible}
                footer={newLocationDialogFooter}
                onHide={props.closeDialog}
                style={{ width: "35rem" }}
                header="NOVA LOCALIDADE"
                modal
                className="p-fluid"
            >
                <div className="grid">
                    <div className="p-field col-6">
                        <strong>Estado:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-server"></i>
                            </span>
                            <Dropdown
                                optionLabel="value"
                                optionValue="value"
                                options={getUFList}
                                placeholder="Estado"
                                value={props.selectedState}
                                onChange={(e: any) => updateCitiesByState(e)}
                                className={classNames({ "p-invalid": submitted && !props.selectedState })}
                            />
                        </div>
                        {submitted && !props.selectedState && <small className="p-invalid">Selecione um Estado.</small>}
                    </div>
                    <div className="p-field col-6">
                        <strong>Cidade:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-server"></i>
                            </span>
                            <Dropdown
                                emptyMessage="Necessário Selecionar um Estado"
                                optionLabel="description"
                                optionValue="id"
                                options={locations}
                                placeholder="Cidade"
                                value={selectedLocation.locationId}
                                onChange={(e: any) => onDropdownChange(e)}
                                disabled={loadingCities} // Desativa o dropdown enquanto `loadingCities` for verdadeiro
                                className={classNames({
                                    "p-invalid": submitted && selectedLocation === emptyLocation,
                                })}
                            />
                        </div>
                        {submitted && selectedLocation === emptyLocation && (
                            <small className="p-invalid">Selecione uma Cidade.</small>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
};
