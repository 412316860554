import React, { useEffect, useState } from 'react';
import { ToastServices } from '../../../services/utils/toast-services';
import { PageHeader } from '../../../views/page-header';
import { CameraIntelligenceResponse } from '../../Camera/types/types';
import { LocationBikerBehaviorCameraGrid } from '../components/location-biker-behavior-camera-grid';
import { LocationBikerBehaviorConfig } from '../components/location-biker-behavior-config';
import { LocationBikerBehaviorHeader } from '../components/location-biker-behavior-header';
import { LocationBikerBehaviorWarning } from '../components/location-biker-behavior-warning';
import { BikerBehaviorServices } from '../../../services/biker-behavior-services';
import { BikerBehaviorConfig, BlankBikerBehaviorConfig } from '../types/types';
import { CameraServices } from '../../../services/camera-services';

export const BikerBehaviorConfiguration = () => {
    const _toastServices = new ToastServices();
    const _cameraServices = new CameraServices();
    const _bikerBehaviorServices = new BikerBehaviorServices();

    const [loading, setLoading] = useState<boolean>(true);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [bikerConfig, setBikerConfig] = useState<BikerBehaviorConfig>(BlankBikerBehaviorConfig);
    const [cameras, setCameras] = useState<CameraIntelligenceResponse[]>([]);

    const isModelValid = selectedLocation !== 0 && bikerConfig.startTime && bikerConfig.stopTime;
    const filteredCameras = cameras.filter(camera => camera.camera?.toLowerCase().includes(globalFilter.toLowerCase()));
    useEffect(() => {
        if (selectedLocation !== 0) {
            getLocationConvoyTime(selectedLocation);
            getLocationCameras(selectedLocation);
        }
    }, [selectedLocation])

    const getLocationConvoyTime = (selectedLocation: number) => {
        setLoading(true);
        _bikerBehaviorServices.getBikerBehaviorTime(selectedLocation).then((data) => {
            var locationConvoyTime: BikerBehaviorConfig = {
                locationId: data.data.locationId,
                startTime: new Date(data.data.startTime),
                stopTime: new Date(data.data.stopTime),
            };
            setLoading(false);
            setBikerConfig(locationConvoyTime);
        }).catch((error) => { _toastServices.showErrorRequestMessage(error); setLoading(false); });
    }
    const getLocationCameras = (selectedLocation: number) => {
        setLoading(true);
        _cameraServices.getCamerasIntelligenceConfiguration(selectedLocation)
            .then(data => {
                setCameras(data.data);
                setLoading(false);
                setLoading(false);
            }).catch((error) => { _toastServices.showErrorRequestMessage(error); setLoading(false); });
    }

    const onSaveConfig = () => {
        if (isModelValid) {
            setLoading(true);
            _bikerBehaviorServices.updateBikerBehaviorServices(selectedLocation, bikerConfig.startTime, bikerConfig.stopTime)
                .then(data => {
                    setLoading(false);
                    _toastServices.showSuccessMessage('Horário da análise de motoqueiros atualizado com sucesso');
                }).catch((error) => { _toastServices.showErrorRequestMessage(error); setLoading(false); });
        }
    }

    return (
        <div className='grid'>
            <div className='col-12'>
                <PageHeader
                    title="CONFIGURAÇÃO ANÁLISE COMPORTAMENTAL DE MOTOQUEIROS"
                    icon="pi pi-chart-bar"
                />
                <div className='card'>
                    <LocationBikerBehaviorWarning
                        startHour={bikerConfig.startTime}
                        stopHour={bikerConfig.stopTime}
                    />
                    <LocationBikerBehaviorHeader
                        loading={loading}
                        setLoading={setLoading}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                    />
                    <LocationBikerBehaviorConfig
                        loading={loading}
                        saveConfig={onSaveConfig}
                        config={bikerConfig}
                        selectedLocation={selectedLocation}
                        updateConfig={setBikerConfig}
                    />
                    <LocationBikerBehaviorCameraGrid
                        cameras={filteredCameras}
                        getLocationCameras={getLocationCameras}
                        loading={loading}
                        selectedLocation={selectedLocation}
                        setLoading={setLoading}
                    />
                </div>
            </div>
        </div>
    )
}
