import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { Fieldset } from "primereact/fieldset";
import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import { DropdownInfo } from "../../../models/types";
import { MachineryVehicleServices } from "../../../services/machinery-vehicle-services";
import { CameraServices } from "../../../services/camera-services";
import { FeatureServices } from "../../../services/feature-services";
import { AuthServices } from "../../../services/utils/auth-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import { CameraMachineryVehicleResponse, CameraIntelligenceResponse } from "../../Camera/types/types";
import { MachineryVehicleConfiguration } from "../types/types";
import "../styles/machinery-vehicle.scss";
import { LocationMachineryVehicleHeader } from "./components/location-machinery-vehicle-header";
import { TimeMachineryVehicleConfig } from "./components/time-machinery-vehicle-config";
import { WhatsappMachineryVehicleConfig } from "./components/whatsapp-machinery-vehicle-config";

const locationConfig: MachineryVehicleConfiguration = {
    locationId: 5,
    startTime: new Date(),
    stopTime: new Date(),
    whatsappGroupId: null
};
export const MachineryVehicleData = () => {
    const _authServices = new AuthServices();
    const _toastServices = new ToastServices();
    const _cameraServices = new CameraServices();
    const _machineryVehicleServices = new MachineryVehicleServices();
    const _featureServices = new FeatureServices();

    const [loading, setLoading] = useState<boolean>(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [loadingCameras, setLoadingCameras] = useState<boolean>(true);
    const [cameraList, setCameraList] = useState<CameraIntelligenceResponse[]>([]);
    const [machineryVehicleConfig, setMachineryVehicleConfig] = useState<MachineryVehicleConfiguration>(locationConfig);

    const [currentPage, setCurrentPage] = useState(0); // Estado para armazenar a página atual

    _authServices.HandleAppAuth();

    const isModelValid = selectedLocation !== 0 && machineryVehicleConfig.startTime && machineryVehicleConfig.stopTime;
    useEffect(() => {
        setLoading(true);
        _featureServices
            .getLocationsByFeature("MACHINERY-ALARM")
            .then((data: any) => {
                setLocations(data.data);
                setLoading(false);
            })
            .catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (selectedLocation !== 0) {
            getLocationMachineryVehicleTime(selectedLocation);
            getCameraMachineryVehicleConfiguration(selectedLocation);
        }
    }, [selectedLocation])

    const onPageChange = (event: any) => {
        setCurrentPage(event.first / event.rows); // Atualiza a página com base no índice
    };

    const getFilteredCameraList = () => {
        if (globalFilter) {
            return cameraList.filter((camera) => camera.camera.toLowerCase().includes(globalFilter.toLowerCase()));
        }

        return cameraList;
    }

    const onSelectedUF = (e: { value: any }) => {
        setLoading(true);
        setSelectedLocation(e.value);
        setLoadingCameras(true);
    }

    const getLocationMachineryVehicleTime = (selectedLocation: number) => {
        _machineryVehicleServices
            .getLocationMachineryVehicleTime(selectedLocation)
            .then((data: any) => {
                var locationMachineryVehicleTime: MachineryVehicleConfiguration = {
                    locationId: data.data.locationId,
                    startTime: new Date(data.data.startTime),
                    stopTime: new Date(data.data.stopTime),
                    whatsappGroupId: data.data.locationWhatsappGroupId
                };

                setMachineryVehicleConfig(locationMachineryVehicleTime);
            })
            .catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
                setLoadingCameras(false);
            });
    }

    const getCameraMachineryVehicleConfiguration = (selectedLocation: number) => {
        setLoadingCameras(true);

        _cameraServices
            .getCamerasIntelligenceConfiguration(selectedLocation)
            .then((data) => {
                setCameraList(data.data);
                setLoadingCameras(false);
                setLoading(false);
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoadingCameras(false);
            });
    }

    const onChangedMachineryVehicle = (cameraMachineryVehicle: CameraMachineryVehicleResponse) => {
        _cameraServices
            .setCameraIntelligenceConfiguration({
                cameraId: cameraMachineryVehicle.cameraId,
                machineryMode: !cameraMachineryVehicle.indMachineryVehicle,
            }).then((data) => {
                _toastServices.showSuccessMessage("Camera " + cameraMachineryVehicle.camera + " atualizada");
                // getCameraMachineryVehicleConfiguration(selectedLocation);
                // Atualiza apenas a câmera modificada sem resetar a lista
                setCameraList((prevCameraList) =>
                    prevCameraList.map((camera) =>
                        camera.cameraId === cameraMachineryVehicle.cameraId
                            ? { ...camera, indMachineryVehicle: !camera.indMachineryVehicle }
                            : camera
                    )
                );
            })
            .catch((error) => {
                _toastServices.showErrorRequestMessage(error);
            });
    }

    // const getImageSrc = (cameraId: number, locationId: number): string => {
    //     return environment().baseUrl + environment().version + `/dashboard/snapshot/${locationId}/${cameraId}`;
    // }

    const getToggleButton = (cameraMachineryVehicle: CameraMachineryVehicleResponse) => {
        let machineryVehicleEnabled: boolean = cameraMachineryVehicle.indMachineryVehicle;
        let label: string = "DESLIGADO";
        let icon: string = "pi pi-times";
        let severity: any = "warning";

        if (machineryVehicleEnabled === true) {
            label = "LIGADO";
            icon = "pi pi-check";
            severity = "success";
        }

        return <Button severity={severity} label={label} icon={icon} onClick={(e) => onChangedMachineryVehicle(cameraMachineryVehicle)} className="w-9rem" />;
    }

    const renderGridItem = (cameraMachineryVehicle: CameraMachineryVehicleResponse) => {
        return (
            <>
                <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2">
                    <div className="p-4 border-1 surface-border surface-card border-round">
                        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                            <div className="flex align-items-center gap-2">
                                <i className="pi pi-camera"></i>
                                <span className="product-category">{cameraMachineryVehicle.camera}</span>
                            </div>
                        </div>
                        <div className="flex justify-content-between">

                            <div>
                                <span className="text-2xl font-semibold">Modo Alerta Maquinário</span>
                                <div className="col-12">{getToggleButton(cameraMachineryVehicle)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const itemTemplate = (cameraMachineryVehicle: CameraMachineryVehicleResponse) => {
        if (!cameraMachineryVehicle) {
            return;
        }

        return renderGridItem(cameraMachineryVehicle);
    }

    const renderSkeletonDataScroller = () => {
        return (
            <>
                <div className="flex justify-content-center">
                    <div className="product-item mr-2">
                        <Skeleton shape="rectangle" width="520px" height="113px" />
                    </div>
                    <div className="product-item mr-2">
                        <Skeleton shape="rectangle" width="520px" height="113px" />
                    </div>
                    <div className="product-item mr-2">
                        <Skeleton shape="rectangle" width="520px" height="113px" />
                    </div>
                </div>
            </>
        );
    }

    const onSaveConfig = (successMessage: string) => () => {
        if (isModelValid) {
            setLoading(true);
            _machineryVehicleServices
                .updateMachineryVehicleTime(
                    selectedLocation,
                    machineryVehicleConfig.startTime,
                    machineryVehicleConfig.stopTime,
                    machineryVehicleConfig.whatsappGroupId
                )
                .then((data: any) => {
                    setLoading(false);
                    _toastServices.showSuccessMessage(successMessage); // Use the passed message
                })
                .catch((error: any) => {
                    _toastServices.showErrorRequestMessage(error);
                })
                .finally(() => setLoading(false)); // Ensure loading is reset even on error
        }
    };


    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader title="CONFIGURAÇÃO DE ALERTA MAQUINÁRIOS" icon="pi pi-chart-bar" />
                <div className="card">
                    <LocationMachineryVehicleHeader
                        globalFilter={globalFilter}
                        loading={loading}
                        locations={locations}
                        onSelectedUF={onSelectedUF}
                        selectedLocation={selectedLocation}
                        setGlobalFilter={setGlobalFilter}
                    />
                    {selectedLocation !== 0 && !loading && (
                        <div className="grid">
                            <div className='col-12'>
                                <Fieldset legend="MODO ALERTA MAQUINÁRIO" toggleable>
                                    <p className="m-0 mb-1">
                                        {/* <strong>✦ MAQUINÁRIO:</strong> */}
                                        <p className="m-2">Caso uma câmera com a opção do <strong>Modo Alerta Maquinário</strong> ativada registre a passagem de veículos tratores, o moon irá gerar um alerta sonoro e visual na tela de DashBoard ou no Mapa.<br />
                                            E caso a localidade habilite a notifição via Whatsapp, o mesmo alarme também poderá ser disparado via integração Whatsapp, o modo de evento só funcionará durante o intervalo de tempo configurado pelo usuário.</p>
                                    </p>
                                </Fieldset>
                            </div>
                            <div className="col-12">
                                <TimeMachineryVehicleConfig
                                    loading={loading}
                                    machineryVehicleConfiguration={machineryVehicleConfig}
                                    saveConfig={onSaveConfig("Horário de modo maquinário atualizado com sucesso")}
                                    updateConfig={setMachineryVehicleConfig}
                                />
                                <WhatsappMachineryVehicleConfig
                                    machineryVehicleConfig={machineryVehicleConfig}
                                    saveConfig={onSaveConfig("Notificações do modo maquinário atualizado com sucesso")}
                                    setMachineryVehicleConfig={setMachineryVehicleConfig}
                                />
                            </div>

                        </div>
                    )}
                    <div className="col-12">
                        {selectedLocation !== 0 && loadingCameras ? renderSkeletonDataScroller() :
                            <DataView
                                header="Câmeras da Localidade"
                                value={getFilteredCameraList()}
                                itemTemplate={itemTemplate}
                                layout="grid"
                                emptyMessage="Nenhuma imagem encontrada para o filtro selecionado"
                                rows={12}
                                paginator
                            />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
