import axios from "axios";
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";

export class MachineryVehicleServices {
    baseControllerUrl: string = environment().baseUrl + environment().version + "/machinery";
    _authServices = new AuthServices();

    getMachineryVehicleEvents(cameraId: number, locationId: number) {
        return axios.get(this.baseControllerUrl + "/get-events", {
            headers: {
                Authorization: this._authServices.GetUserToken()
            },
            params: {
                locationId: locationId === 0 ? null : locationId,
                cameraId: cameraId === 0 ? null : cameraId,
            },
        });
    }

    updateMachineryVehicleTime(locationId: number, startTime: Date, stopTime: Date, whatsappGroupdId: number) {
        return axios.put(this.baseControllerUrl + '/update-machinery-vehicle-time/' + locationId, {}, {
            headers: {
                Authorization: this._authServices.GetUserToken()
            },
            params: {
                startTime: startTime.toLocaleString(),
                stopTime: stopTime.toLocaleString(),
                whatsappGroupId: whatsappGroupdId
            }
        });
    }

    getLocationMachineryVehicleTime(locationId: number) {
        return axios.get(this.baseControllerUrl + '/' + locationId, {
            headers: {
                Authorization: this._authServices.GetUserToken()
            },
        });
    }

}
