import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { DropdownInfo } from "../../../src/models/types";
import { onInputChange } from "../../services/utils/input-services";
import { WhatsAppServices } from "../../services/whatsapp-services";

export interface WhatsappGroupProps {
    selectedGroupId: number | null; // Allow null for "all groups"
    selectedLocation: number;
    object: any;
    objectUpdate: (object: any) => void;
}

const whatsappService = new WhatsAppServices();

export const WhatsappGroup = (props: WhatsappGroupProps) => {
    const [locationGroups, setLocationGroups] = useState<DropdownInfo[]>([]);
    const [loading, setLoading] = useState(false); // Optional: for better UX

    useEffect(() => {
        const fetchGroups = async () => {
            if (props.selectedLocation !== 0) {
                setLoading(true);
                try {
                    const response = await whatsappService.getWhatsappGroupsByLocation(props.selectedLocation);
                    const responseData: DropdownInfo[] = response.data;

                    const allGroups: DropdownInfo = {
                        description: "Notificar todos grupos",
                        id: null, // Matches the "all groups" option
                    };
                    const updatedGroups = [allGroups, ...responseData];

                    setLocationGroups(updatedGroups);
                } catch (error) {
                    console.error("Failed to fetch WhatsApp groups:", error);
                    setLocationGroups([]); // Reset on error
                } finally {
                    setLoading(false);
                }
            } else {
                setLocationGroups([]); // Clear if no valid location
            }
        };

        fetchGroups();
    }, [props.selectedLocation]);

    return (
        <div className="grid">
            <div className="col-12">
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-fw pi-users"></i>
                    </span>
                    <Dropdown
                        emptyMessage="Nenhum Grupo Encontrado"
                        optionLabel="description"
                        optionValue="id"
                        options={locationGroups}
                        placeholder="Notificar Todos Grupos"
                        value={props.selectedGroupId} // Should match an option's id
                        onChange={(e) => onInputChange(e, "whatsappGroupId", props.object, props.objectUpdate)}
                        disabled={loading || props.selectedLocation === 0} // Optional: disable while loading or invalid location
                    />
                </div>
            </div>
        </div>
    );
};
