import React, { useEffect, useState } from "react";
import { BlackListServices } from "../../../services/black-list-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { PageHeader } from "../../../views/page-header";
import { blackListItem, blankBlackList } from "../types/types";
import { BlackListWarning } from "./components/black-list-warning";
import { BlackListDataTable } from "./data-tables/black-list-data-table";
import { CreateEventDialog } from "./dialogs/create-event-dialog";
import { DeleteEventDialog } from "./dialogs/delete-event-dialog";
import { DeleteEventsDialog } from "./dialogs/delete-events-dialog";

export const BlackList = () => {
    const _toastServices = new ToastServices();
    const _blackListServices = new BlackListServices();

    const [loading, setLoading] = useState<boolean>(false);
    const [events, setEvents] = useState<blackListItem[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const [event, setEvent] = useState<blackListItem>(blankBlackList);
    const [eventDialog, setEventDialog] = useState<boolean>(false);
    const [selectedEvents, setSelectedEvents] = useState<blackListItem[]>([]);
    const [deleteEventDialog, setDeleteEventDialog] = useState<boolean>(false);
    const [deleteEventsDialog, setDeleteEventsDialog] = useState<boolean>(false);
    const [descriptionIsRequired, setDescriptionIsRequired] = useState<boolean>(false);

    useEffect(() => {
        getBlackLists();
    }, [])

    const getBlackLists = () => {
        _blackListServices.getBlackLists()
            .then((response: any) => {
                setEvents(response.data)
            })
    }

    const createBlackList = () => {
        setLoading(true);
        _blackListServices.createBlackList(event)
            .then(() => {
                _toastServices.showSuccessMessage("Black List criada com sucesso");
                getBlackLists();
            }).catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
            })
        setLoading(false);
    }

    const updateBlackList = () => {
        setLoading(true);
        _blackListServices.updateBlackList(event)
            .then((response) => {
                _toastServices.showSuccessRequestMessage(response);
                getBlackLists();
            }).catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
            });
        setLoading(false);
    }

    const deleteBlackList = () => {
        setLoading(true);
        setDeleteEventDialog(false)
        _blackListServices.deleteBlackList(event)
            .then((response) => {
                _toastServices.showSuccessRequestMessage(response);
                getBlackLists()
            }).catch((error: any) => {
                _toastServices.showErrorRequestMessage(error);
            });
        setLoading(false);
    }

    const deleteBlackLists = () => {
        setDeleteEventsDialog(false)
        selectedEvents.forEach((plate: blackListItem) => {
            _blackListServices.deleteBlackList(event)
                .then((response) => {
                    _toastServices.showSuccessRequestMessage(response);
                    getBlackLists()
                }).catch((error: any) => {
                    _toastServices.showErrorRequestMessage(error);
                })
        })
        setSelectedEvents([]);
    }

    const onCreateNewBlackList = () => {
        setEvent(blankBlackList);
        setIsEditing(false)
        setDescriptionIsRequired(false)
        setSubmitted(false);
        setEventDialog(true);
    }

    const confirmDeleteSelected = () => {
        setDeleteEventsDialog(true);
    }

    const editPlateReason = (plate: blackListItem) => {
        setIsEditing(true)
        const showOtherFiled = plate.reason === 'Outra'
        setDescriptionIsRequired(showOtherFiled)
        setEvent({ ...plate });
        setEventDialog(true);
    }

    const confirmDeletePlate = (plate: blackListItem) => {
        setEvent(plate);
        setDeleteEventDialog(true);
    }

    const hideDeleteEventDialog = () => {
        setDeleteEventDialog(false);
    }

    const hideDeleteEventsDialog = () => {
        setDeleteEventsDialog(false);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setEventDialog(false);
    }

    const isFormValid = () => {
        const formFields = [`plate`, `reason`, `otherReason`, `description`]
        let isValid = true
        formFields.forEach((field) => {
            switch (field) {
                case `plate`:
                    if (event.plate === `` || event.plate.length < 1) isValid = false
                    return
                case `reason`:
                    if (event.reason === ``) isValid = false
                    return
                case `description`:
                    if (event.description === `` && descriptionIsRequired) {
                        isValid = false
                    }
                    return
            }
        }
        )
        return isValid
    }

    const saveEvent = () => {
        setSubmitted(true);

        if (!isFormValid()) return

        if (event.idBlackList) {
            updateBlackList()
        }
        else {
            createBlackList()
        }
        setEventDialog(false)
    }

    return (
        <div className="grid">
            <div className="col-12">
                <PageHeader
                    title="CONFIGURAÇÃO BLACK LIST COMPORTAMENTAL"
                    icon="pi pi-chart-bar"
                />
                <BlackListWarning />
                <div className="card">
                    <BlackListDataTable
                        confirmDeleteEvent={confirmDeletePlate}
                        confirmDeleteSelected={confirmDeleteSelected}
                        editEventReason={editPlateReason}
                        globalFilter={globalFilter}
                        events={events}
                        loading={loading}
                        openNew={onCreateNewBlackList}
                        selectedEvents={selectedEvents}
                        setGlobalFilter={setGlobalFilter}
                        setSelectedEvents={setSelectedEvents}
                    />
                    <CreateEventDialog
                        event={event}
                        hideDialog={hideDialog}
                        isEditing={isEditing}
                        saveEvent={saveEvent}
                        setEvent={setEvent}
                        submitted={submitted}
                        visible={eventDialog}
                    />
                    <DeleteEventDialog
                        deleteEvent={deleteBlackList}
                        event={event}
                        hideDialog={hideDeleteEventDialog}
                        visible={deleteEventDialog}
                    />
                    <DeleteEventsDialog
                        deleteEvents={deleteBlackLists}
                        event={event}
                        hideDialog={hideDeleteEventsDialog}
                        visible={deleteEventsDialog}
                    />
                </div>
            </div>
        </div>
    )
}
