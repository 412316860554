import React from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../../styles/dsmApplication.scss";
import { dsmApplication } from "../../types/types";
import { toast, ToastContainer } from "react-toastify";
import { ToastServices } from "../../../../services/utils/toast-services";
import { DSMApplicationServices } from "../../../../services/dsm-application-services";

export interface DeleteDSMApplicationDialogProps {
    dsmApplication: dsmApplication;
    setLoading: (isLoading: boolean) => void;
    setDSMApplication: (dsmApplication: dsmApplication) => void;
    getDSMApplications: () => void;
    emptyDSMApplication: dsmApplication;
    setDeleteDSMApplicationDialog: (showDSMApplicationDialog: boolean) => void;
    visible: boolean;
}

export const DeleteDSMApplicationDialog = (props: DeleteDSMApplicationDialogProps) => {
    const dsmApplicationService = new DSMApplicationServices();
    const _toastService = new ToastServices();
    const hideDeleteDSMApplicationDialog = () => {
        props.setDeleteDSMApplicationDialog(false);
    };

    const deleteDSMApplication = () => {
        props.setLoading(true);
        dsmApplicationService.deleteDSMApplication(props.dsmApplication.dsmApplicationId).then(
            () => {
                props.setDeleteDSMApplicationDialog(false);
                props.setDSMApplication(props.emptyDSMApplication);
                props.setLoading(false);
                _toastService.showSuccessMessage(`Servidor DSM ${props.dsmApplication.dsmApplicationName} Excluído com Sucesso.`);
                props.getDSMApplications();
            },
            (error) => {
                props.getDSMApplications();
                _toastService.showErrorRequestMessage(error);
                props.setLoading(false);
            }
        );
    };

    const deleteDSMApplicationDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDSMApplicationDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteDSMApplication} />
        </>
    );

    return (
        <Dialog visible={props.visible} style={{ width: "450px" }} header="Confirmação" modal footer={deleteDSMApplicationDialogFooter} onHide={hideDeleteDSMApplicationDialog}>
            <ToastContainer />
            <Toast ref={toast} />
            <div className="confirmation-content">
                <i className="pi pi-exclamation-triangle p-mr-3 " style={{ fontSize: "2rem" }} />
                {props.dsmApplication && (
                    <span className="ml-2">
                        Você têm certeza que deseja excluir o servidor DSM <b>{props.dsmApplication.dsmApplicationName}</b>?
                    </span>
                )}
            </div>
        </Dialog>
    );
};
