import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import React, { useEffect, useMemo } from 'react';
import { onTimeChange } from '../../../../services/utils/input-services';
import { ClonedVehicleConfiguration } from '../../types/types';
import { Fieldset } from 'primereact/fieldset';
import { LocationClonedVehicleWarning } from './cloned-vehicle-warning';

export interface locationClonedVehicleConfigProps {
    clonedVehicleConfiguration: ClonedVehicleConfiguration,
    loading: boolean,
    updateConfig: (config: ClonedVehicleConfiguration) => void,
    saveConfig: () => void
}

const header = <>
    <span className="titulo-categoria">
        <i className='pi pi-clock mr-2'></i>
        Horário de Funcionamento
    </span>
</>

// Função auxiliar para validar e ajustar a data com fuso horário de São Paulo (UTC-3)
const ensureValidDate = (time: Date | null | undefined): Date => {
    // Se o valor for undefined, null ou não for uma data válida, retorna a data padrão em UTC-3
    if (!time || isNaN(new Date(time).getTime())) {
        const defaultDate = new Date('1970-01-01T00:00:00.000-03:00'); // UTC-3 explícito
        return defaultDate;
    }
    return new Date(time); // Retorna a data válida (ajustada pelo fuso local do sistema)
};

export const TimeClonedVehicleConfig = (props: locationClonedVehicleConfigProps) => {
    // Usamos useMemo para garantir que os valores sejam ajustados apenas quando necessário
    const adjustedStartTime = useMemo(() => {
        return ensureValidDate(props.clonedVehicleConfiguration.startTime);
    }, [props.clonedVehicleConfiguration.startTime]);

    const adjustedStopTime = useMemo(() => {
        return ensureValidDate(props.clonedVehicleConfiguration.stopTime);
    }, [props.clonedVehicleConfiguration.stopTime]);

    // Opcional: Atualizar o estado com os valores ajustados
    useEffect(() => {
        const hasInvalidStartTime = !props.clonedVehicleConfiguration.startTime || isNaN(new Date(props.clonedVehicleConfiguration.startTime).getTime());
        const hasInvalidStopTime = !props.clonedVehicleConfiguration.stopTime || isNaN(new Date(props.clonedVehicleConfiguration.stopTime).getTime());

        if (hasInvalidStartTime || hasInvalidStopTime) {
            props.updateConfig({
                ...props.clonedVehicleConfiguration,
                startTime: hasInvalidStartTime ? new Date('1970-01-01T00:00:00.000-03:00') : props.clonedVehicleConfiguration.startTime,
                stopTime: hasInvalidStopTime ? new Date('1970-01-01T00:00:00.000-03:00') : props.clonedVehicleConfiguration.stopTime,
            });
        }
    }, [props.clonedVehicleConfiguration.startTime, props.clonedVehicleConfiguration.stopTime, props.updateConfig]);

    return (
        <>
            <Fieldset legend={header} className='mb-4'>
                <LocationClonedVehicleWarning
                    startHour={adjustedStartTime}
                    stopHour={adjustedStopTime}
                />
                <div className='grid'>
                    <div className='col-2'>
                        <div className="p-inputgroup">
                            <strong className='mr-2'>Inicio:</strong>
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-clock" />
                            </span>
                            <Calendar
                                hourFormat='24'
                                stepMinute={30}
                                disabled={props.loading}
                                value={adjustedStartTime}
                                showOnFocus
                                onChange={(e: any) => onTimeChange(e, 'startTime', props.clonedVehicleConfiguration, props.updateConfig)}
                                timeOnly
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className="p-inputgroup">
                            <strong className='mr-2'>Fim:</strong>
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-clock" />
                            </span>
                            <Calendar
                                timeOnly
                                showOnFocus
                                stepMinute={30}
                                disabled={props.loading}
                                value={adjustedStopTime}
                                onChange={(e: any) => onTimeChange(e, 'stopTime', props.clonedVehicleConfiguration, props.updateConfig)}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <Button label='ATUALIZAR HORÁRIOS' onClick={(e) => { props.saveConfig() }} />
                    </div>
                </div>
            </Fieldset>
        </>
    );
};
