import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect } from "react";
import { dsmApplicationLocation, emptyDSMApplicationLocation } from "../../types/types";
import { DropdownInfo } from "../../../../models/types";

export interface CreateDSMApplicationLocationDialogProps {
    visible: boolean;
    closeDialog: () => void;
    dsmApplicationLocations: dsmApplicationLocation[];
    setSelectedLocations: (locations: DropdownInfo[]) => void;
    selectedLocations: DropdownInfo[];
    isEditing: boolean;
}

export const CreateDSMApplicationLocationDialog = (props: CreateDSMApplicationLocationDialogProps) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<dsmApplicationLocation>(emptyDSMApplicationLocation);

    useEffect(() => {
        setSelectedLocation(emptyDSMApplicationLocation);
    }, [props.visible]);

    const addLocationToAllowedDSMApplicationLocations = () => {
        const locationToAdd: dsmApplicationLocation = {
            locationId: selectedLocation.locationId,
            locationName: selectedLocation.locationName,
        };
        props.dsmApplicationLocations.push(locationToAdd);
    };

    const sortAllowedDSMApplicationLocations = () => {
        const propertyName: string = "locationName";
        props.dsmApplicationLocations.sort((a: any, b: any) => {
            if (a[propertyName] < b[propertyName]) return -1;
            if (a[propertyName] > b[propertyName]) return 1;
            return 0;
        });
    };

    const addNewLocation = () => {
        setSubmitted(true);

        if (selectedLocation && selectedLocation.locationId) {
            props.setSelectedLocations([...props.selectedLocations, { id: selectedLocation.locationId, description: selectedLocation.locationName }]);
            props.closeDialog();
            setSubmitted(false);
            addLocationToAllowedDSMApplicationLocations();
            sortAllowedDSMApplicationLocations();
            setSelectedLocation(emptyDSMApplicationLocation);
        }
    };

    const newLocationDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={props.closeDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={addNewLocation} />
        </>
    );

    const onDropdownChange = (e: any) => {
        const locationDropdownObject = props.selectedLocations.find((x) => x.id === e.value);
        if (locationDropdownObject) {
            const locationObject: dsmApplicationLocation = {
                locationId: locationDropdownObject.id,
                locationName: locationDropdownObject.description,
            };
            setSelectedLocation(locationObject);
        }
    };

    return (
        <>
            <Dialog visible={props.visible} footer={newLocationDialogFooter} onHide={props.closeDialog} style={{ width: "35rem" }} header="NOVA LOCALIDADE" modal className="p-fluid">
                <div className="grid">
                    <div className="p-field col-6">
                        <strong>Cidade:</strong>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-server"></i>
                            </span>
                            <Dropdown
                                emptyMessage="Necessário Selecionar um Estado"
                                optionLabel="description"
                                optionValue="id"
                                options={props.selectedLocations}
                                placeholder="Cidade"
                                value={selectedLocation.locationId}
                                onChange={onDropdownChange}
                                className={classNames({ "p-invalid": submitted && selectedLocation.locationId === emptyDSMApplicationLocation.locationId })}
                            />
                        </div>
                        {submitted && selectedLocation.locationId === emptyDSMApplicationLocation.locationId && <small className="p-invalid">Selecione uma Cidade.</small>}
                    </div>
                </div>
            </Dialog>
        </>
    );
};
