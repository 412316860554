import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useState } from "react";
import { NavigateToLocation } from "../../../components/Navigation/navigate-to-location";
import { DropdownInfo } from "../../../models/types";
import { FeatureServices } from "../../../services/feature-services";
import { ToastServices } from "../../../services/utils/toast-services";
import { WhatsAppServices } from "../../../services/whatsapp-services";
import { PageHeader } from "../../../views/page-header";
import { whatsappRecipient } from "../types/types";
import { whatsappGroup } from "../types/types";
import { WhatsappDataTable } from "./data-tables/whatsapp-data-table";
import { CreateRecipientDialog } from "./dialogs/create-recipient-dialog";
import { DeleteRecipientDialog } from "./dialogs/delete-recipient-dialog";
import { DeleteGroupDialog } from "./dialogs/delete-group-dialog";
import { EditGroupDialog } from "./dialogs/edit-group-dialog";

const blankWhatsappRecipient: whatsappRecipient = {
    recipientId: null,
    active: true,
    createStamp: '',
    recipientCode: '55',
    recipientName: '',
    whatsappGroupId: null,
    whatsappGroupName: null
};

const blankWhatsappGroup: whatsappGroup = {
    locationWhatsappGroupId: null,
    active: true,
    locationId: null,
    createStamp: '',
    groupName: '',
};

export const WhatsApp = () => {
    const _toastServices = new ToastServices();
    const _featureServices = new FeatureServices();
    const _whatsappServices = new WhatsAppServices();

    const [loading, setLoading] = useState<boolean>(true);
    const [locations, setLocations] = useState<DropdownInfo[]>();
    const [locationGroups, setLocationGroups] = useState<DropdownInfo[]>();
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [selectedLocationGroup, setSelectedLocationGroup] = useState<number>(0);
    const [recipients, setRecipients] = useState<whatsappRecipient[]>();
    const [createRecipientDialog, setCreateRecipientDialog] = useState<boolean>(false);
    const [editGroupDialog, setEditGroupDialog] = useState<boolean>(false);
    const [recipient, setRecipient] = useState<whatsappRecipient>(blankWhatsappRecipient);
    const [group, setGroup] = useState<whatsappGroup>(blankWhatsappGroup);
    const [deleteRecipientDialog, setDeleteRecipientDialog] = useState<boolean>(false);
    const [deleteGroupDialog, setDeleteGroupDialog] = useState<boolean>(false);
    const [selectedRecipients, setSelectedRecipients] = useState<whatsappRecipient[]>();
    const [dialogHeader, setDialogHeader] = useState<string>();
    const [globalFilter, setGlobalFilter] = useState<string>();
    const [submitted, setSubmitted] = useState<boolean>(false);

    useEffect(() => {
        loadLocations();
    }, [])


    const loadLocations = () => {
        setLoading(true);

        _featureServices.getLocationsByFeature('WHATSAPP-NOTIFICATION')
            .then((data: any) => {
                setLocations(data.data);
                setLoading(false);
            }).catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    }

    const loadWhatsappGroupsByLocation = (locationId: number) => {
        setLoading(true);
        _whatsappServices.getWhatsappGroupsByLocation(locationId)
            .then((data: any) => {
                setLocationGroups(data.data);
                setLoading(false);
            }).catch(error => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            })
    }

    const loadWhatsappGroupById = (groupId: number) => {
        setLoading(true);
        _whatsappServices.getWhatsappGroupById(groupId)
            .then((data: any) => {
                setGroup(data.data);
                setLoading(false);
            }).catch(error => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            })
    }

    const loadWhatsappRecipientsByGroup = (groupId: number) => {
        setLoading(true);
        _whatsappServices.getWhatsappRecipientsByGroup(groupId)
            .then((data: any) => {
                setRecipients(data.data);
                setLoading(false);
            }).catch(error => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            })
    }

    const onSelectedLocation = (e: { value: any }) => {
        setSelectedLocation(e.value);
        setSelectedLocationGroup(0);
        setRecipients([]);
        loadWhatsappGroupsByLocation(e.value);
    }

    const onSelectedLocationGroup = (e: { value: any }) => {
        setSelectedLocationGroup(e.value);
        loadWhatsappRecipientsByGroup(e.value);
        loadWhatsappGroupById(e.value);
    }

    const onEditRecipient = (recipient: whatsappRecipient) => {
        var location = locations.find(x => x.id === selectedLocation);
        setDialogHeader(`EDITANDO RECIPIENTE DE ${location.description}`);
        setSubmitted(false);
        setRecipient(recipient);
        setCreateRecipientDialog(true);
    }

    const onDeleteRecipient = (recipient: whatsappRecipient) => {
        setRecipient(recipient);
        setDeleteRecipientDialog(true);
    }

    const onEditGroup = (selectedLocationGroup: number) => {
        setDialogHeader(`EDITANDO GRUPO DE ${group.groupName}`);
        setSubmitted(false);
        setEditGroupDialog(true);
    }

    const onDeleteGroup = (selectedLocationGroup: number) => {
        setGroup(group);
        setDeleteGroupDialog(true);
    }


    const onConfirmedRecipientDeletion = () => {
        setLoading(true);
        if (recipient.recipientId) {
            _whatsappServices.deleteWhatsappRecipient(recipient.recipientId)
                .then(data => {
                    setSubmitted(false);
                    onSavingRecipientLoading();
                    setLoading(false);
                    _toastServices.showSuccessMessage("Recipiente excluído com sucesso");
                }).catch((error) => {
                    _toastServices.showErrorRequestMessage(error);
                    setLoading(false);
                });
        }
        setDeleteRecipientDialog(false);
    }

    const onConfirmedGroupDeletion = () => {
        setLoading(true);
        if (group.locationWhatsappGroupId) {
            _whatsappServices.deleteWhatsappGroup(group.locationWhatsappGroupId)
                .then(data => {
                    setSubmitted(false);
                    onSavingGroupLoading();
                    setLoading(false);
                    _toastServices.showSuccessMessage("Grupo excluído com sucesso");
                }).catch((error) => {
                    _toastServices.showErrorRequestMessage(error);
                    setLoading(false);
                });
        }
        setDeleteGroupDialog(false);
    }

    const onCloseCreationDialog = () => {
        setCreateRecipientDialog(false);
        setEditGroupDialog(false);
    }

    const onCloseDeletionDialog = () => {
        setDeleteRecipientDialog(false);
        setDeleteGroupDialog(false);
    }

    const onSaveRecipient = () => {
        setSubmitted(true);
        setLoading(true);

        if (isRecipientValid(recipient)) {
            if (recipient.recipientId) {
                _whatsappServices.updateWhatsappRecipient(recipient)
                    .then(data => {
                        onSavingRecipientLoading();
                        setSubmitted(false);
                        setLoading(false);
                        _toastServices.showSuccessMessage("Recipiente atualizado com sucesso");
                    }).catch((error) => {
                        _toastServices.showErrorRequestMessage(error);
                        setLoading(false);
                    });
            } else {
                _whatsappServices.createWhatsappRecipient(recipient, selectedLocation)
                    .then(data => {
                        onSavingRecipientLoading();
                        setSubmitted(false);
                        setLoading(false);
                        _toastServices.showSuccessMessage("Recipiente criado com sucesso");
                    }).catch((error) => {
                        _toastServices.showErrorRequestMessage(error);
                        setLoading(false);
                    });
            }

            setCreateRecipientDialog(false);
        }
    }

    const onSaveGroup = () => {
        setSubmitted(true);
        setLoading(true);

        if (isGroupValid(group)) {
            if (group.locationWhatsappGroupId) {
                _whatsappServices.updateWhatsappGroup(group)
                    .then(data => {
                        onSavingGroupLoading();
                        setSubmitted(false);
                        setLoading(false);
                        _toastServices.showSuccessMessage("Grupo atualizado com sucesso");
                    }).catch((error) => {
                        _toastServices.showErrorRequestMessage(error);
                        setLoading(false);
                    });
            };
            // else {
            //         _whatsappServices.createWhatsappRecipient(recipient, selectedLocation)
            //             .then(data => {
            //                 onSavingRecipientLoading();
            //                 setSubmitted(false);
            //                 setLoading(false);
            //                 _toastServices.showSuccessMessage("Recipiente criado com sucesso");
            //             }).catch((error) => {
            //                 _toastServices.showErrorRequestMessage(error);
            //                 setLoading(false);
            //             });

            setEditGroupDialog(false);
        }
    }

    const onCreateRecipient = () => {
        var location = locations.find(x => x.id === selectedLocation);
        setDialogHeader(`CRIANDO NOVO RECIPIENTE DE ${location.description}`);
        setRecipient(blankWhatsappRecipient);
        setCreateRecipientDialog(true);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-1">
                    <Button icon='pi pi-plus' className="p-button-success ml-2" label="Novo" onClick={(e) => onCreateRecipient()} disabled={selectedLocation === 0} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        disabled={loading}
                        value={globalFilter}
                        placeholder="Buscar recipientes"

                        onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                </span>
            </React.Fragment>
        )
    }

    const isRecipientValid = (recipient: whatsappRecipient): boolean => {
        if (!recipient || !recipient.recipientCode || !recipient.recipientName || recipient.recipientCode.length < 3 || selectedLocation === 0) {
            return false;
        }

        return true;
    }

    const isGroupValid = (group: whatsappGroup): boolean => {
        if (!group || !group.groupName) {
            return false;
        }

        return true;
    }

    const onSavingRecipientLoading = () => {
        loadWhatsappGroupsByLocation(selectedLocation);
        if (selectedLocationGroup !== 0) {
            loadWhatsappRecipientsByGroup(selectedLocationGroup);
        }
    }

    const onSavingGroupLoading = () => {
        loadWhatsappGroupsByLocation(selectedLocation);
        if (selectedLocationGroup !== 0) {
            loadWhatsappRecipientsByGroup(selectedLocationGroup);
        }
    }

    const actionsTemplate = () => {
        if (!selectedLocationGroup) { return <></> }
        return (
            <React.Fragment>
                <div className="actions">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded p-button mr-2"
                        onClick={() => onEditGroup(selectedLocationGroup)}
                    />
                    <Button
                        icon="pi pi-trash"
                        className="p-button-rounded p-button-danger"
                        onClick={() => onDeleteGroup(selectedLocationGroup)}
                    />
                </div>
            </React.Fragment>
        );

    }

    return (
        <div>
            <PageHeader
                title="CONFIGURAÇÃO DE RECIPIENTES DO WHATSAPP"
                icon="pi pi-chart-bar"
            />
            <NavigateToLocation
                icon="pi-spin pi-cog"
                text="CONFIGURAR NOTIFICAÇÃO DE EVENTOS"
                to="whatsapp-features"
            />
            <div className="card">
                <div className="grid">
                    <div className="col-2">
                        <div className="p-inputgroup mb-2">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-map-marker"></i>
                            </span>
                            <Dropdown
                                options={locations}
                                disabled={loading}
                                value={selectedLocation}
                                tooltip="Selecione uma localidade para carregar os grupos"
                                placeholder="Selecione uma localidade"
                                emptyMessage="Nenhuma mensagem encontrada"
                                optionValue="id"
                                optionLabel="description"
                                onChange={(e) => onSelectedLocation(e)}
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="p-inputgroup mb-2">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-users"></i>
                            </span>
                            <Dropdown
                                options={locationGroups}
                                disabled={loading || selectedLocation === 0}
                                value={selectedLocationGroup}
                                tooltip="Selecione uma grupo para carregar os recipientes"
                                placeholder="Selecione um grupo"
                                emptyMessage="Nenhum grupo encontrado"
                                optionValue="id"
                                optionLabel="description"
                                onChange={(e) => onSelectedLocationGroup(e)}
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="p-inputgroup mb-2">
                            {actionsTemplate()}
                        </div>
                    </div>

                </div>
                <Toolbar
                    className="p-mb-4"
                    left={leftToolbarTemplate}
                    right={rightToolbarTemplate}
                />
                <WhatsappDataTable
                    loading={loading}
                    globalFilter={globalFilter}
                    recipents={recipients}
                    selectedRecipents={selectedRecipients}
                    setSelectedRecipients={setSelectedRecipients}
                    deleteRecipient={onDeleteRecipient}
                    editRecipient={onEditRecipient}
                />

                <CreateRecipientDialog
                    locationGroups={locationGroups}
                    closeDialog={onCloseCreationDialog}
                    recipient={recipient}
                    saveRecipient={onSaveRecipient}
                    submitted={submitted}
                    setRecipient={setRecipient}
                    dialogHeader={dialogHeader}
                    visible={createRecipientDialog}
                    locationId={selectedLocation}
                />

                <DeleteRecipientDialog
                    visible={deleteRecipientDialog}
                    closeDialog={onCloseDeletionDialog}
                    deleteRecipient={onConfirmedRecipientDeletion}
                    recipient={recipient}
                />

                <EditGroupDialog
                    closeDialog={onCloseCreationDialog}
                    saveGroup={onSaveGroup}
                    submitted={submitted}
                    dialogHeader={dialogHeader}
                    visible={editGroupDialog}
                    group={group}
                    setGroup={setGroup}
                />

                <DeleteGroupDialog
                    visible={deleteGroupDialog}
                    closeDialog={onCloseDeletionDialog}
                    deleteGroup={onConfirmedGroupDeletion}
                    group={group}
                />
            </div>
        </div>
    );
}
