import React, { useEffect, useRef, useState } from "react";
import { DropdownInfo } from "../../../models/types";
import { LocationServices } from "../../../services/location-services";
import { SignalRLiveMap } from "./signal-r-live-map";
import { MapServices } from "../../../services/map-services";
import { Camera, location, cardResponse, routeResponse } from "../types/types";
import '../styles/map.css';
import '../styles/dropdown.css';
import '../styles/slide.css';
import '../styles/side-bar.css';
import '../styles/page-header.css';
import { DropdownFilters } from "./filters/dropdown-filters";
import { Slide } from "./components/slide-map";
import { SideBarMenu } from "./components/side-bar";
import { Dialog } from "primereact/dialog";
// import Legend from "./components/legend";
import { VehicleRoute } from "./components/vehicle-route";
import Legend from "./components/legend";
import { PageHeader } from "../../../views/page-header";

interface MapProps {
}

export const Map: React.FC<MapProps> = (props: MapProps) => {
    const today: Date = new Date();
    const _locationServices = new LocationServices();
    const _mapServices = new MapServices();

    const [cameras, setCameras] = useState<Camera[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [date, setDate] = useState<Date>(today);
    const [showPdfDialog, setShowPdfDialog] = useState<boolean>(false);
    const [selectedTimeSpan, setSelectedTimeSpan] = useState<number>(15);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [listLocations, setListLocations] = useState<location[]>([]);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(true);
    const [selectedCamera, setSelectedCamera] = useState<number>(0);
    const [camerasList, setCamerasList] = useState<DropdownInfo[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [selectedRouteLocation, setSelectedRouteLocation] = useState<number>(0);
    const [signalRConnected, setSignalRConnected] = useState<boolean>(false);
    const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(true);
    // const [isLegendPopupOpen, setIsLegendPopupOpen] = useState<boolean>(false);
    const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState<boolean>(false);
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState<boolean>(false);
    const [isRoutePopupOpen, setIsRoutePopupOpen] = useState<boolean>(false);


    const [routeList, setRouteList] = useState<routeResponse[]>([]); // Armazena a lista de rotas selecionadas

    // Agora `selectedVehicleTypes` é um array de chaves de `cardResponse`
    const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<(keyof cardResponse)[]>([
        'quantityCar',
        'quantityMotorBike',
        'quantityTruck',
        'quantityMachinery',
        'quantityBus',
        'quantityPickup',
        'quantityOther',
    ]);

    const dropdownRef = useRef<HTMLDivElement>(null);

    // Estados para o Heatmap controlado pelo slide
    const [intensityFactor, setIntensityFactor] = useState<number>(1); // Fator de Intensidade
    const [radius, setRadius] = useState<number>(50);  // Raio
    const [blur, setBlur] = useState<number>(35);     // Blur
    const [maxZoom, setMaxZoom] = useState<number>(17);  // MaxZoom
    // const [mapTopOffset, setMapTopOffset] = useState<number>(0);

    const lastSelectedCamera = useRef<number>(0);
    const lastSelectedLocation = useRef<number>(0);
    const lastSelectedDate = useRef<Date>(today);
    const lastListLocations = useRef<location[]>([]);

    lastSelectedDate.current = date;
    lastSelectedCamera.current = selectedCamera;
    lastSelectedLocation.current = selectedLocation;
    lastListLocations.current = listLocations;

    // Carregar locations e câmeras
    useEffect(() => {
        getLocations();
        getCameras();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // // Atualiza a altura do mapa com base nas alturas do Toolbar e Dropdown
    // useEffect(() => {
    //     const updateMapHeight = () => {
    //         const toolbarHeight = toolbarRef.current?.offsetHeight || 0;
    //         const dropdownHeight = dropdownRef.current?.offsetHeight || 0;
    //         setMapTopOffset(toolbarHeight + dropdownHeight);
    //     };

    //     updateMapHeight();

    //     window.addEventListener("resize", updateMapHeight);

    //     return () => {
    //         window.removeEventListener("resize", updateMapHeight);
    //     };
    // }, []);

    // useEffect(() => {
    // }, [isLegendPopupOpen]);

    const getLocations = () => {
        setLoadingLocations(true);
        _locationServices.getActiveMapLocations().then((response: any) => {
            let locations: DropdownInfo[] = [{ description: "TODAS LOCALIDADES", id: 0 }];
            response.data.forEach((location: any) => {
                locations.push({
                    description: location.description,
                    id: location.id,
                } as DropdownInfo);
            });

            let list_locations: location[] = [];
            response.data.forEach((location: any) => {
                list_locations.push({
                    id: location.id,
                    latitude: location.latitude,
                    longitude: location.longitude
                } as location);
            });
            setLocations(locations);
            setListLocations(list_locations);

            setLoadingLocations(false);
        });
    };

    const getCameras = () => {
        setLoading(true);

        _mapServices.getCameras()
            .then((data) => {
                let cameras = data.data;
                let list_cameras: DropdownInfo[] = [{ description: "TODAS CAMERAS", id: 0 }];
                cameras.forEach((camera: Camera) => {
                    list_cameras.push({
                        description: camera.dscCamera,
                        id: camera.id,
                    } as DropdownInfo);
                });

                setCameras(cameras);
                setCamerasList(list_cameras);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const getListCameras = (locationId: number) => {
        _mapServices.getCamerasDataByLocation(locationId)
            .then((data) => {
                let cameras = data.data;
                let list_cameras: DropdownInfo[] = [{ description: "TODAS CAMERAS", id: 0 }];
                cameras.forEach((camera: Camera) => {
                    list_cameras.push({
                        description: camera.dscCamera,
                        id: camera.id,
                    } as DropdownInfo);
                });

                setCameras(cameras);
                setCamerasList(list_cameras);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const onSelectedLocation = (e: { value: any }) => {
        const selectedItem = listLocations.find(location => location.id === e.value);

        if (selectedItem) {
            setSelectedLocation(e.value);
            setSelectedCamera(0);
            getListCameras(e.value);
        } else if (e.value === 0) {
            setSelectedLocation(0);
            getCameras();
        }
    };

    const onSelectedRouteLocation = (e: { value: any }) => {
        const selectedItem = listLocations.find(location => location.id === e.value);

        if (selectedItem) {
            setSelectedRouteLocation(e.value);
        } else if (e.value === 0) {
            setSelectedRouteLocation(0);
        }
    };

    const onSelectedCamera = (e: { value: any }) => {
        setSelectedCamera(e.value);
    }

    const onSelectedDate = (e: { value: any }) => {
        setDate(e.value);
    }

    // Handler para os tipos de veículos selecionados
    const onSelectedVehicleTypes = (selectedTypes: (keyof cardResponse)[]) => {
        setSelectedVehicleTypes(selectedTypes);
    };

    const toggleSideBar = () => {
        setIsSideBarOpen(!isSideBarOpen);
    };

    // const toggleLegendPopup = () => {
    //     setIsLegendPopupOpen(!isLegendPopupOpen);
    // };

    const toggleSettingsPopup = () => {
        setIsSettingsPopupOpen(!isSettingsPopupOpen);
    };

    const toggleFitlerPopup = () => {
        setIsFilterPopupOpen(!isFilterPopupOpen);
    };

    const toggleRoutePopup = () => {
        setIsRoutePopupOpen(!isRoutePopupOpen);
    };

    const onSelectRoute = (routes: routeResponse[]) => {
        setRouteList(routes); // Atualiza o estado com a lista de rotas selecionadas
    }

    if (loading) {
        return <div>Loading map...</div>;
    }

    return (
        <div className="map-container">

            {/* Barra lateral retrátil com o menu */}
            <div className={`side-bar ${isSideBarOpen ? "open" : "closed"}`}>
                <button onClick={toggleSideBar} className="toggle-button">
                    {isSideBarOpen ? "<<" : ">>"}
                </button>
                {/* Passa a função de toggle para o menu */}
                <SideBarMenu
                    isSideBarOpen={isSideBarOpen}
                    // onLegendClick={toggleLegendPopup}
                    onSettingsClick={toggleSettingsPopup}
                    onFilterClick={toggleFitlerPopup}
                    onRouteClick={toggleRoutePopup}
                />
            </div>

            <div className="page-header">
                <PageHeader
                    title="MAPA TÉRMICO INTELIGENTE"
                    icon="pi pi-map"
                />
            </div>

            <div className="legend">
                <Legend
                    intensityFactor={intensityFactor}
                    blur={blur}
                    radius={radius}
                    maxZoom={maxZoom}
                    maxVehicles={5000}
                />
            </div>

            {/* Mapa preenchendo o espaço restante */}
            <div className="map-content">
                <SignalRLiveMap
                    isRoutePopupOpen={isRoutePopupOpen}
                    // isLegendPopupOpen={isLegendPopupOpen}
                    isSettingsPopupOpen={isSettingsPopupOpen}
                    isFilterPopupOpen={isFilterPopupOpen}
                    loading={loading}
                    cameras={cameras}
                    selecteDate={lastSelectedDate.current}
                    selectedCamera={lastSelectedCamera.current}
                    selectedLocation={lastSelectedLocation.current}
                    listLocations={lastListLocations.current}
                    setSignalRConnected={setSignalRConnected}
                    onSelectedCamera={onSelectedCamera}
                    selectedVehicleTypes={selectedVehicleTypes}  // Passa os tipos de veículos selecionados para o mapa
                    intensityFactor={intensityFactor}  // Fator de Intensidade
                    radius={radius}  // Raio do Heatmap
                    blur={blur}  // Blur do Heatmap
                    maxZoom={maxZoom}  // MaxZoom do Heatmap
                    routeList={routeList} // Passa a lista de rotas como propriedade
                />
            </div>

            {/* <Dialog
                header="Legenda do Mapa"
                visible={isLegendPopupOpen}
                onHide={() => setIsLegendPopupOpen(false)}
            >
                <Legend
                    intensityFactor={intensityFactor}
                    blur={blur}
                    radius={radius}
                    maxZoom={maxZoom}
                    maxVehicles={5000}
                />
            </Dialog> */}

            <Dialog
                header="Configurações"
                visible={isSettingsPopupOpen}
                onHide={() => setIsSettingsPopupOpen(false)}
            >
                <Slide
                    intensityFactor={intensityFactor} setIntensityFactor={setIntensityFactor}
                    radius={radius} setRadius={setRadius}
                    blur={blur} setBlur={setBlur}
                    maxZoom={maxZoom} setMaxZoom={setMaxZoom}
                />
            </Dialog>


            <Dialog
                header="Filtros"
                visible={isFilterPopupOpen}
                onHide={() => setIsFilterPopupOpen(false)}
            >
                {/* Dropdown Filters com referência */}
                <div ref={dropdownRef} className="dropdown-filters">
                    <DropdownFilters
                        date={date}
                        today={today}
                        cameras={camerasList}
                        locations={locations}
                        onSelectedLocation={onSelectedLocation}
                        showPdfDialog={showPdfDialog}
                        loading={loading}
                        onSelectedDate={onSelectedDate}
                        selectedCamera={selectedCamera}
                        selectedTimeSpan={selectedTimeSpan}
                        setShowPdfDialog={setShowPdfDialog}
                        loadingLocations={loadingLocations}
                        onSelectedCamera={onSelectedCamera}
                        selectedLocation={selectedLocation}
                        onSelectedTimeSpan={setSelectedTimeSpan}
                        signalRConnected={signalRConnected}
                        selectedVehicleTypes={selectedVehicleTypes}  // Passa os tipos de veículos selecionados
                        onSelectedVehicleTypes={onSelectedVehicleTypes}  // Função para selecionar tipos de veículos
                    />
                </div>
            </Dialog>

            <Dialog
                visible={isRoutePopupOpen}
                onHide={() => setIsRoutePopupOpen(false)}
            >
                <VehicleRoute
                    setIsRoutePopupOpen={setIsRoutePopupOpen}
                    locations={locations}
                    loading={loading}
                    onSelectRoute={onSelectRoute}
                    onSelectedRouteLocation={onSelectedRouteLocation}
                    selectedRouteLocation={selectedRouteLocation}
                />
            </Dialog>

        </div>
    );
};
